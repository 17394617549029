// colors
import { Primary, Action } from "../colors";

export const MuiSelectComponent = {
    styleOverrides: {
        root: {
            "&::before": {
                borderBottom: `1px solid ${Action[85]}`,
            },

            borderRadius: "4px",
            backgroundColor: Action.background[40],

            // icon color
            ".MuiSvgIcon-root": {
                color: Action[50],
            },
        },
        disabled: {
            backgroundColor: Primary.background,
            minWidth: "258px",
        },
    },
};
