// theme
import { PaletteOptions } from "@mui/material";

// colors
import { Primary, Secondary, Error, Success, Warning, Info } from "./colors";

// palette options
export const paletteOptions: PaletteOptions = {
    primary: {
        main: Primary.main,
        light: Primary[60],
        dark: Primary[30],
        contrastText: Primary.white,
    },
    secondary: {
        main: Secondary.main,
        dark: Secondary[40],
        light: Secondary[60],
        contrastText: Secondary.white,
    },
    info: {
        main: Info.main,
        dark: Info[40],
        light: Info[60],
        contrastText: Info.white,
    },
    error: {
        main: Error.main,
        dark: Error[30],
        light: Error[60],
        contrastText: Error.white,
    },
    success: {
        main: Success.main,
        dark: Success[30],
        light: Success[60],
        contrastText: Success.white,
    },
    warning: {
        main: Warning.main,
        dark: Warning[45],
        light: Warning[60],
        contrastText: Warning.white,
    },
};
