// libs
import { Button } from "@mui/material";
import React, { ReactNode } from "react";

// types
import { SxProps } from "@mui/material";

// styles
import { pageTitleBarButton } from "./PageTitleBarButton.style";

export interface IPageTitleBarButtonProps {
    enabled: boolean;
    label: string;
    onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
    sx?: SxProps;
    children?: ReactNode;
}

const PageTitleBarButton: React.FC<IPageTitleBarButtonProps> = (props: IPageTitleBarButtonProps) => {
    return (
        <Button
            disabled={!props.enabled}
            variant="outlined"
            onClick={props.onClick}
            color="primary"
            sx={{ ...pageTitleBarButton, ...props?.sx }}>
            {props.label}
        </Button>
    );
};

export default React.memo(PageTitleBarButton);
