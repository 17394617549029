// types
import { SxProps } from "@mui/material";

// colors
import { Action, Primary } from "../../../styles/colors";

export const titleContainer: SxProps = {
    padding: "12px",
    borderRadius: "4px",
    color: Primary.white,
    backgroundColor: Primary[10],
};

export const noInputSelected: SxProps = {
    py: 2,
    display: "flex",
    color: Action[70],
    justifyContent: "center",
    border: `1px solid ${Action[90]}`,
};

export const quickSightDashboard: SxProps = {
    width: "100%",
    height: "100vh",
    border: `1px solid ${Action[90]}`,
};
