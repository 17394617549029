// types
import { SxProps } from "@mui/material";

// styles
import { theme } from "../../../../styles";
import { grey } from "../../../../styles/colors";

export const sandboxInfo: SxProps = { width: "100%", boxShadow: theme.shadows[1] };

export const sandboxInfoDetail: SxProps = {
    paddingTop: "8px",
    color: grey,
    fontSize: "14px",
};

export const moreResources: SxProps = {
    width: "100%",
    boxShadow: theme.shadows[1],
};

export const moreResourcesLink: SxProps = {
    fontSize: "14px",
};
