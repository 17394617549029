// colors
import { Primary } from "../colors";

export const MuiDrawerComponent = {
    styleOverrides: {
        paper: {
            backgroundColor: Primary.background,
            minWidth: "258px",
        },
    },
};
