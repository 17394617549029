// libs
import { useCallback } from "react";

// constants
import { FeatureFlag } from "../../constants/FeatureFlags";

// services
import { useGetFeatureFlags } from "../../services/FeatureFlagService";

interface IFeatureFlags {
    featureFlags: Object;
    isFeatureEnable: (featureFlag: FeatureFlag) => boolean;
}

const useFeatureFlags = (userAlias: string): IFeatureFlags => {
    const { data: featureFlags } = useGetFeatureFlags(userAlias);

    const isFeatureEnable = useCallback(
        (featureFlag: FeatureFlag) => {
            return !!featureFlags && featureFlags[featureFlag]?.enabled;
        },
        [featureFlags],
    );

    return {
        isFeatureEnable,
        featureFlags,
    };
};

export default useFeatureFlags;
