const Action = {
    /**The token represents the action.main token */
    main: "#808080",
    /**The token represent the white color*/
    white: "#ffffff",
    /**The token represent the action background color*/
    background: {
        10: "#FEFEFE",
        20: "#FCFCFC",
        30: "#FBFBFB",
        40: "#FAFAFA",
        50: "#F9F9F9",
        60: "#F7F7F7",
        70: "#F6F6F6",
        80: "#F5F5F5",
        90: "#F3F3F3",
        100: "#F2F2F2",
    },
    /**The token represents 95% lighter shade of the action.white token */
    95: "#F2F2F2",
    /**The token represents 90% lighter shade of the action.white token */
    90: "#E6E6E6",
    /**The token represents 85% lighter shade of the action.white token */
    85: "#D9D9D9",
    /**The token represents 80% lighter shade of the action.white token */
    80: "#CCCCCC",
    /**The token represents 75% lighter shade of the action.white token */
    75: "#BFBFBF",
    /**The token represents 70% lighter shade of the action.white token */
    70: "#B3B3B3",
    /**The token represents 65% lighter shade of the action.white token */
    65: "#A6A6A6",
    /**The token represents 60% lighter shade of the action.white token */
    60: "#999999",
    /**The token represents 55% lighter shade of the action.white token */
    55: "#8C8C8C",
    /**The token represents 50% lighter shade of the action.white token */
    50: "#808080",
    /**The token represents 45% lighter shade of the action.white token */
    45: "#737373",
    /**The token represents 40% lighter shade of the action.white token */
    40: "#666666",
    /**The token represents 35% lighter shade of the action.white token */
    35: "#595959",
    /**The token represents 30% lighter shade of the action.white token */
    30: "#4D4D4D",
    /**The token represents 25% lighter shade of the action.white token */
    25: "#404040",
    /**The token represents 20% lighter shade of the action.white token */
    20: "#333333",
    /**The token represents 15% lighter shade of the action.white token */
    15: "#262626",
    /**The token represents 10% lighter shade of the action.white token */
    10: "#1A1A1A",
    /**The token represents 5% lighter shade of the action.white token */
    5: "#0D0D0D",
    /**The token represents 0% lighter shade of the action.white token */
    0: "#000000",
};

export default Action;
