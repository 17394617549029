// libs
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

// components
import { Modal, Grid, Stack, Typography, CircularProgress } from "@mui/material";

// colors
import { Action } from "../../styles/colors";

export interface ILoadingModalProps {
    forceLoading?: boolean;
}

const LoadingModal: React.FC<ILoadingModalProps> = ({ forceLoading = false }: ILoadingModalProps) => {
    const loading = useSelector(({ loader }: any) => loader.loadingCount > 0);

    const showLoading = useMemo(() => !!loading || !!forceLoading, [loading, forceLoading]);

    return (
        <Modal open={showLoading} disableEscapeKeyDown={true} onClose={() => false}>
            <Grid container alignContent="center" justifyContent="center" sx={{ width: "100vw", height: "100vh" }}>
                <Grid item sx={{ backgroundColor: Action.white, p: 4, borderRadius: 2 }}>
                    <Stack alignItems="center" spacing={2}>
                        <CircularProgress />
                        <Typography>Loading...</Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default React.memo(LoadingModal);
