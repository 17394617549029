// theme
import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions = {
    h1: {
        fontFamily: "Amazon Ember",
        fontSize: "96px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "116.7%" /* 112.032px */,
        letterSpacing: "-1.5px",
    },
    h2: {
        fontFamily: "Amazon Ember",
        fontSize: "60px",
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "120%" /* 72px */,
        letterSpacing: "-0.5px",
    },
    h3: {
        fontFamily: "Amazon Ember",
        fontSize: "48px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "116.70%" /* 56.016px */,
    },
    h4: {
        fontFamily: "Amazon Ember",
        fontSize: "34px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "123.50%" /* 41.99px */,
        letterSpacing: "0.25px",
    },
    h5: {
        fontFamily: "Amazon Ember",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "133.4%" /* 32.016px */,
    },
    h6: {
        fontFamily: "Amazon Ember",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "160%" /* 32px */,
        letterSpacing: "0.15px",
    },
    subtitle1: {
        fontFamily: "Amazon Ember",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "143%" /* 22.88px */,
        letterSpacing: "0.15px",
    },
    subtitle2: {
        fontFamily: "Amazon Ember",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "157%" /* 21.98px */,
        letterSpacing: "0.1px",
    },
    body1: {
        fontFamily: "Amazon Ember",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%" /* 24px */,
        letterSpacing: "0.15px",
    },
    body2: {
        fontFamily: "Amazon Ember",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "143%" /* 20.02px */,
        letterSpacing: "0.17px",
    },
    button: {
        fontFamily: "Amazon Ember",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "26px" /* 173.333% */,
        letterSpacing: "0.46px",
        textTransform: "capitalize",
    },
    // body2 : {
    //     fontFamily: "Amazon Ember",
    //     fontSize: "14px",
    //     fontStyle: "normal",
    //     fontWeight: 400,
    //     lineHeight: "24px" /* 171.429% */,
    //     letterSpacing: "0.4px",
    //     textTransform: "capitalize",
    // },
    // body2 : {
    //     fontFamily: "Amazon Ember",
    //     fontSize: "13px",
    //     fontStyle: "normal",
    //     fontWeight: 400,
    //     lineHeight: "22px" /* 169.231% */,
    //     letterSpacing: "0.46px",
    //     textTransform: "capitalize",
    // },
    caption: {
        fontFamily: "Amazon Ember",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "166%" /* 19.92px */,
        letterSpacing: "0.4px",
    },
    overline: {
        fontFamily: "Amazon Ember",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "266%" /* 31.92px */,
        letterSpacing: "1px",
        textTransform: "uppercase",
    },
};
