// types
import { IAlert } from "../types/Alert";
import { SEVERITY } from "../types/Severity";

// constants
import { FeatureFlag } from "./FeatureFlags";

export const INFO_MESSAGE: { [key: string]: IAlert } = {
    [FeatureFlag.SANDBOX_GLOBAL_LOCK]: {
        title: "Scheduled Downtime",
        content: "A3S is offline for maintenance from 11/17 to 11/25. Please contact Patrick Goss (patgoss) for any further assistance.",
        severity: SEVERITY.INFO,
    },
};
