// libs
import { createTheme } from "@mui/material";

// typography options
import { typography } from "./Typography";

// palette options
import { paletteOptions } from "./Palette";

// components
import {
    MuiChipComponent,
    MuiGridComponent,
    MuiDrawerComponent,
    MuiSelectComponent,
    MuiAccordionSummaryComponent,
    MuiDialogComponent,
} from "./components";

export const theme = createTheme({
    typography: { fontFamily: ["Amazon Ember"].join(","), ...typography },
    palette: paletteOptions,
    components: {
        MuiChip: MuiChipComponent,
        MuiGrid: MuiGridComponent,
        MuiDialog: MuiDialogComponent,
        MuiDrawer: MuiDrawerComponent,
        MuiSelect: MuiSelectComponent,
        MuiAccordionSummary: MuiAccordionSummaryComponent,
    },
});
