// libs
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

// utils
import { getClient, graphQlQueries } from "../utils/appsync";

// defs
import { AllocationConfigSchema, GetAllocationConfigContentQuery, GetAllocationConfigContentResponse } from "../utils/appsync/schema/API";

export const useGetAllocationConfigSchema = (allocation_type, input_type): UseQueryResult<Partial<AllocationConfigSchema>, Error> => {
    return useQuery<Partial<AllocationConfigSchema>, Error>({
        queryKey: ["allocationConfigSchema", allocation_type, input_type],
        queryFn: async () => {
            const { data: allocationConfigSchema }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlQueries.GetAllocationConfigSchema,
                variables: {
                    allocation_type,
                    input_type,
                },
            });

            return {
                column_schema: allocationConfigSchema.GetAllocationConfigSchema.column_schema,
            };
        },
        enabled: !!allocation_type && !!input_type,
        // setting staleTime and gcTime to 5 mins.
        staleTime: 5 * 60 * 1000,
        gcTime: 5 * 60 * 1000,
    });
};

export const useGetAllocationConfig = (
    allocation_type,
    input_type,
    limit = 1000,
    offset = 0,
    allocation_pool_filters = [""],
): UseQueryResult<Partial<GetAllocationConfigContentResponse>, Error> => {
    return useQuery<Partial<GetAllocationConfigContentResponse>, Error>({
        queryKey: ["allocationConfig", allocation_type, input_type],
        queryFn: async () => {
            const { data: allocationConfig }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlQueries.GetAllocationConfigContent,
                variables: {
                    limit,
                    offset,
                    allocation_pool_filters,
                    allocation_type,
                    input_type,
                },
            });

            return {
                content: JSON.parse((allocationConfig as GetAllocationConfigContentQuery).GetAllocationConfigContent.content),
                last_modified: (allocationConfig as GetAllocationConfigContentQuery).GetAllocationConfigContent.last_modified,
            };
        },
        enabled: !!allocation_type && !!input_type,
        // setting staleTime and gcTime to 5 mins.
        staleTime: 5 * 60 * 1000,
        gcTime: 5 * 60 * 1000,
    });
};
