// libs
import { TooltipProps } from "@mui/material";
import React from "react";

// components
import { Tooltip } from "@mui/material";

// styles
import { theme } from "../../styles";
import { white, grey100 } from "../../styles/colors";

// props
export interface ICustomTooltipProps extends TooltipProps {}

const CustomTooltip: React.FC<ICustomTooltipProps> = ({ title, placement, children }) => {
    return (
        <Tooltip
            title={title}
            placement={placement}
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: white,
                        color: grey100,
                        padding: 2,
                        fontSize: theme.typography.fontSize,
                        boxShadow: theme.shadows[4],
                    },
                },
            }}>
            {children}
        </Tooltip>
    );
};

export default React.memo(CustomTooltip);
