// libs
import React from "react";

// components
import { Grid, Typography } from "@mui/material";

// styles
import { grey } from "../../../../styles/colors";

const Tenets: React.FC = () => {
    return (
        <Grid item display="flex" flexDirection="column" rowGap={3} p={8} md={8}>
            <Grid item container>
                <Typography variant="h5" gutterBottom>
                    Tenets
                </Typography>
            </Grid>

            <Grid item container flexDirection="column">
                <Typography variant="subtitle2">Greater visibility of allocation simulations</Typography>
                <Typography variant="body2" color={grey}>
                    Finance users can personalize allocation simulations to display post allocated data. Based on simulations, users can
                    also see the complete P&L view and impact to Amazon Segments.
                </Typography>
            </Grid>

            <Grid item container flexDirection="column">
                <Typography variant="subtitle2">Greater accuracy and controllership</Typography>
                <Typography variant="body2" color={grey}>
                    A3S owns the production rules and restatement rules separately and merges them when required during MEC and QEC. A
                    single system allowing versioning, export functionality with security features that restrict allocation rules update.
                </Typography>
            </Grid>

            <Grid item container flexDirection="column">
                <Typography variant="subtitle2">Single source of truth</Typography>
                <Typography variant="body2" color={grey}>
                    A3S helps manage all corporate allocation rules and configurations that can be accessed by business and finance users.
                </Typography>
            </Grid>

            <Grid item container flexDirection="column">
                <Typography variant="subtitle2">Traceable and Explainable</Typography>
                <Typography variant="body2" color={grey}>
                    A3S will provide visibility to all business logic for corporate allocations. It also provides visibility to historical
                    definitions and enables auditing.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default React.memo(Tenets);
