// libs
import { createSearchParams } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";

// components
import { AllCostPool } from "../components/AllCostPool";
import { Grid, Box, Typography, Stack, Chip, IconButton } from "@mui/material";
import InputChangeSelector from "../SimulationResult/components/InputChangeSelector/InputChangeSelector";
import { PageTitleBarButton } from "../../../components/common/PageTitleBar/PageTitleBarButton";
import { PageTitleBarSelect } from "../../../components/common/PageTitleBar/PageTitleBarSelect";
import { PageTitleBarTextField } from "../../../components/common/PageTitleBar/PageTitleBarTextField";

// stores
import { setPageTitle } from "../../../store/page-title-reducer";
import { startLoading, stopLoading } from "../../../store/loader-reducer";

// services
import { useGetSimulation, useSaveSimulation, useSubmitSimulation } from "../../../services/SimulationService";

// types
import { ChipColor } from "../../../types/mui";
import { INPUT_TYPES } from "../../../utils/constants";
import { IAllocationType } from "../../../types/utilities";
import { SimStatus } from "../../../utils/appsync/schema/API";

// styles
import { Action } from "../../../styles/colors";
import { newSimulationTitleContainer, newSimulationSaveAction, newSimulationTextField, newSimulationSelect } from "./NewSimulation.style";

// hooks
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";
import { useAlertMessageHandler } from "../../../hooks/useErrorMessageHandler";

// constants
import { FeatureFlag } from "../../../constants/FeatureFlags";
import { Scenerios, SimulationScenarios, SimulationTimeRanges } from "../constants/Scenerios";

// icons
import { AutoAwesomeRounded, SaveOutlined } from "@mui/icons-material";

const NewSimulation: React.FC = () => {
    const [readOnly, setReadOnly] = useState<boolean>(false);

    const [simulationId, setSimulationId] = useState(null);

    const [simulationData, setSimulationData] = useState<any>({});

    const [disabledTimeRangeSelector, toggleTimeRangeSelector] = useState<boolean>(false);

    const { resourceKey } = useParams();

    const { selectedSandbox, allocationType, username } = useSelector((state: any) => state.userInfo);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { showErrorAlertMessage } = useAlertMessageHandler();

    const submitSimulation = useSubmitSimulation();

    const saveSimulation = useSaveSimulation();

    const { data: simData } = useGetSimulation(selectedSandbox.sandbox_id, simulationId);

    const { isFeatureEnable } = useFeatureFlags(username);

    useEffect(() => {
        if (simData) {
            setSimulationData(simData);
            setReadOnly(simData.status_key !== "ACTIVE#SIM#CREATED");
        }
    }, [simData]);

    useEffect(() => {
        dispatch(
            setPageTitle({
                title: simulationData.resource_id ? "Simulation" : "New Simulation",
                icon: simulationData.resource_id ? undefined : "add",
                crumbs: [
                    {
                        title: "New Simulations",
                        path: "/simulations",
                    },
                    {
                        title: simulationData.resource_id ?? "New",
                    },
                ],
            }),
        );

        // TODO: temp disable the timeRange in case of
        // Scenerio other then `Actuals` & `R&O - Current`, will remove this once we will have
        // range selector (year and month both) feature.
        const secenerio = JSON.parse(simulationData.configs || "{}").scenario || "";
        toggleTimeRangeSelector(!(secenerio === Scenerios.ACTUALS || secenerio === Scenerios["R&O - Current"]));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulationData]);

    useEffect(() => {
        if (selectedSandbox?.sandbox_id) {
            if (resourceKey) {
                setSimulationId(atob(resourceKey));
            } else {
                // reset the page details in case of New Simulation redirection.
                setReadOnly(false);
                setSimulationData({});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSandbox, resourceKey]);

    const onSaveSimulation = useCallback(
        async () => {
            if (readOnly) {
                return "";
            }
            if (!selectedSandbox?.sandbox_id || !simulationData.name) {
                return "";
            }

            const unhashedId = resourceKey ? atob(resourceKey) : simulationData?.resource_id;

            dispatch(startLoading());

            try {
                const savedSimulation = await saveSimulation.mutateAsync({
                    sandbox_id: selectedSandbox.sandbox_id,
                    request: {
                        name: simulationData.name,
                        configs: simulationData.configs,
                        allocation_type: allocationType.key,
                        userAlias: username,
                    },
                    sim_id: unhashedId,
                });
                setSimulationData(savedSimulation);
            } catch ({ errors }) {
                showErrorAlertMessage(errors);
            }

            dispatch(stopLoading());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedSandbox, allocationType, resourceKey, simulationData, username],
    );

    const isSaveEnabled = useCallback(() => {
        const simulationDataConfig = JSON.parse(simulationData.configs ?? "{}");
        return (
            !isFeatureEnable(FeatureFlag.SANDBOX_GLOBAL_LOCK) &&
            !!simulationData.name &&
            !!simulationDataConfig?.scenario &&
            !!simulationDataConfig?.startPeriod &&
            !!simulationDataConfig?.endPeriod
        );
    }, [simulationData]);

    const isStartRunEnabled = useCallback(() => {
        const simulationDataConfig = JSON.parse(simulationData.configs ?? "{}");
        return isSaveEnabled() && (!!simulationDataConfig?.allocation_mapping || !!simulationDataConfig?.allocation_definition);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulationData]);

    const updateFieldInSimulation = (fieldName: string) => {
        return (fieldValue: any) => {
            const configs = JSON.parse(simulationData.configs || "{}");
            if (fieldName === "timeRange") {
                if (fieldValue.length > 0) {
                    fieldValue.sort();
                    const startPeriod = fieldValue[0] + "01";
                    configs["startPeriod"] = startPeriod;

                    const endPeriod = fieldValue[fieldValue.length - 1] + "12";
                    configs["endPeriod"] = endPeriod;

                    fieldValue = [startPeriod, endPeriod];
                }
            } else {
                configs[fieldName] = fieldValue;

                // TODO: temp setting the timeRange in case of
                // Scenerio other then `Actuals` and `R&O - Current`, will remove this once we will have
                // range selector (year and month both) feature.
                if (fieldName === "scenario") {
                    if (!(fieldValue === Scenerios.ACTUALS || fieldValue === Scenerios["R&O - Current"])) {
                        switch (fieldValue) {
                            case Scenerios.ACTUALS:
                                // reset the timeRange values
                                configs["startPeriod"] = undefined;
                                configs["endPeriod"] = undefined;
                                break;
                            case Scenerios["R&O - Current"]:
                                // reset the timeRange values
                                configs["startPeriod"] = undefined;
                                configs["endPeriod"] = undefined;
                                break;
                            case Scenerios.GUIDANCE_Q3:
                                configs["startPeriod"] = "202407";
                                configs["endPeriod"] = "202412";
                                break;
                            case Scenerios.GUIDANCE_Q4:
                                configs["startPeriod"] = "202401";
                                configs["endPeriod"] = "202412";
                                break;
                            case Scenerios.OP1_2024:
                                configs["startPeriod"] = "202401";
                                configs["endPeriod"] = "202412";
                                break;
                            case Scenerios.OP2_2024:
                                configs["startPeriod"] = "202401";
                                configs["endPeriod"] = "202612";
                                break;
                            case Scenerios.OP1_2025:
                                configs["startPeriod"] = "202501";
                                configs["endPeriod"] = "202712";
                                break;
                        }

                        // disable the timeRange selection in case of scenerio other
                        // then `Actuals` and `R&O - Current`
                        toggleTimeRangeSelector(!(fieldValue === Scenerios.ACTUALS || fieldValue === Scenerios["R&O - Current"]));
                    }
                }
            }
            setSimulationData({
                ...simulationData,
                [fieldName]: fieldValue,
                configs: JSON.stringify(configs),
            });
        };
    };

    const getSimulationScenario = useMemo(() => {
        return JSON.parse(simulationData.configs || "{}").scenario || "";
    }, [simulationData]);

    const getSelectedTimeRanges = useMemo(() => {
        if (!disabledTimeRangeSelector) {
            let timeRange = JSON.parse(simulationData.configs || "{}");
            if (!!timeRange.startPeriod && !!timeRange.endPeriod) {
                timeRange = [timeRange.startPeriod, timeRange.endPeriod];
            }
            const timeRanges = [];
            if (timeRange?.length) {
                const startPeriod = parseInt(timeRange[0].substring(0, timeRange[0].length - 2));
                const endPeriod = parseInt(timeRange[1].substring(0, timeRange[1].length - 2));
                for (let i = startPeriod; i <= endPeriod; i++) {
                    timeRanges.push(i.toString());
                }
            }
            return timeRanges;
        }
    }, [disabledTimeRangeSelector, simulationData]);

    const onStartRunSimulation = async (): Promise<void> => {
        if (isStartRunEnabled() && !readOnly && selectedSandbox?.sandbox_id) {
            dispatch(startLoading());

            /** TODO: This is a temporary fix. Later, the submitSimulation call will
             *  handle both saving and starting the simulation actions.
             *  Added to handle the following scenario: If the user starts the simulation
             *  without saving, in that case, the simulation run fails because in submit,
             *  we are only sending the resource_id and sandbox_id. The backend was expecting
             *  all simulation data to exist in the database.
             */
            let unhashedId;
            if (resourceKey || simulationData?.resource_id) {
                unhashedId = resourceKey ? atob(resourceKey) : simulationData?.resource_id;
            }

            try {
                const savedSimulation = await saveSimulation.mutateAsync({
                    sandbox_id: selectedSandbox.sandbox_id,
                    request: {
                        name: simulationData.name,
                        configs: simulationData.configs,
                        allocation_type: allocationType.key,
                        userAlias: username,
                    },
                    sim_id: unhashedId,
                });

                if (savedSimulation) {
                    await submitSimulation.mutateAsync({
                        sandbox_id: selectedSandbox.sandbox_id,
                        sim_id: savedSimulation.resource_id,
                        userAlias: username,
                    });

                    navigate({
                        pathname: "/simulations",
                        search: createSearchParams({
                            sandboxId: selectedSandbox.sandbox_id,
                            allocationType: allocationType.key,
                        }).toString(),
                    });
                }
            } catch ({ errors }) {
                showErrorAlertMessage(errors);
            }
            dispatch(stopLoading());
        }
    };

    const getSelectedInput = useCallback(
        (fieldName: string) => {
            const configs = JSON.parse(simulationData.configs || "{}");
            return configs[fieldName] ?? "";
        },
        [simulationData],
    );

    const getIsAllCostPoolSelected = useMemo(() => {
        return JSON.parse(simulationData.configs || "{}").simulate_all_costpools || false;
    }, [simulationData]);

    const renderNewSimulationActions = (): React.ReactElement => {
        if (readOnly) {
            let variant = "filled";
            let color: ChipColor = "info";
            switch (simulationData?.status?.toUpperCase()) {
                case SimStatus.STARTED:
                    color = "primary";
                    break;
                case SimStatus.FAILED:
                    color = "error";
                    break;
                case SimStatus.CREATED:
                    color = "default";
                    variant = "outlined";
                    break;
                case SimStatus.COMPLETED:
                    color = "success";
                    break;
                case SimStatus.CANCELED:
                    color = "warning";
                    break;
                default:
                    color = "default";
            }
            return <Chip variant={variant as any} color={color} label={simulationData?.status} sx={{ textTransform: "captilise" }} />;
        }

        return (
            <React.Fragment>
                <IconButton disabled={!isSaveEnabled()} onClick={onSaveSimulation} sx={{ ...newSimulationSaveAction }}>
                    <SaveOutlined />
                </IconButton>
                <PageTitleBarButton enabled={isStartRunEnabled()} label="Start Run" onClick={onStartRunSimulation} />
            </React.Fragment>
        );
    };

    const renderAllCostPool = (): React.ReactElement => {
        // add a temp check to hide all cost pool feature for all sandbox
        // except RFA.
        if (!selectedSandbox?.sandbox_name.toLowerCase().includes("rfa")) {
            return <></>;
        }

        return (
            <AllCostPool
                readOnly={readOnly}
                isAllCostPoolSelected={getIsAllCostPoolSelected}
                onAllCostPoolSelected={updateFieldInSimulation("simulate_all_costpools")}
            />
        );
    };

    return (
        <Grid>
            <Box sx={newSimulationTitleContainer}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <AutoAwesomeRounded />
                        <PageTitleBarTextField
                            readonly={readOnly}
                            label="New Simulation"
                            value={simulationData.name}
                            sx={!readOnly ? newSimulationTextField : undefined}
                            onChange={updateFieldInSimulation("name")}
                        />
                        <PageTitleBarSelect
                            readonly={readOnly}
                            label="Scenario"
                            value={getSimulationScenario}
                            options={SimulationScenarios([Scenerios.GUIDANCE_Q2])} // hiding the Guidance Q2
                            sx={!readOnly ? newSimulationSelect : undefined}
                            onChange={updateFieldInSimulation("scenario")}
                        />

                        {!disabledTimeRangeSelector && (
                            <PageTitleBarSelect
                                readonly={readOnly}
                                label="Time"
                                value={getSelectedTimeRanges}
                                options={SimulationTimeRanges}
                                multiple={true}
                                sx={!readOnly ? newSimulationSelect : undefined}
                                onChange={updateFieldInSimulation("timeRange")}
                            />
                        )}
                    </Stack>
                    <Stack direction="row">{renderNewSimulationActions()}</Stack>
                </Stack>
            </Box>
            {renderAllCostPool()}
            <Stack spacing={1} sx={{ pt: 2 }}>
                <Typography variant="caption" color={Action.main}>
                    Select a definition and/or mapping to run the simulation
                </Typography>
                {INPUT_TYPES.map((inputTypeItem: IAllocationType) => (
                    <InputChangeSelector
                        readonly={readOnly}
                        key={allocationType.key + "#" + inputTypeItem.key}
                        allocationType={allocationType.key}
                        inputType={inputTypeItem.key}
                        fieldLabel={inputTypeItem.label}
                        onChange={updateFieldInSimulation(inputTypeItem.key)}
                        currentValue={getSelectedInput(inputTypeItem.key)}
                    />
                ))}
            </Stack>
        </Grid>
    );
};

export default React.memo(NewSimulation);
