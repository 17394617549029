import { TableSandboxFilterRequest } from "./appsync/schema/API";

export const isTableSandboxFilterRequest = (obj: any): obj is TableSandboxFilterRequest => {
    const filter = obj as TableSandboxFilterRequest;
    const keys = Object.keys(filter);

    if (filter && keys.length > 0) {
        let isFormatValid = true;

        // Check for operator exists and the string
        // to filter has at least len 2
        keys.forEach((filterName) => {
            // Get operators
            const filterField = obj[filterName];
            const filterOperators: Array<string> = Object.keys(filterField);
            if (!filterField || !filterOperators.length) {
                isFormatValid = false;
            }
            filterOperators.forEach((operatorStr) => {
                const searchStr = filterField[operatorStr];
                if (!searchStr || searchStr.length < 3) {
                    isFormatValid = false;
                }
            });
        });
        return isFormatValid;
    }
    return false;
};

export const formatMillisecondsToDate = (epochSeconds: number): string => {
    const milliseconds = epochSeconds * 1000;
    const date = new Date(milliseconds);
    return date.toUTCString();
};
