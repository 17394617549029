// libs
import React from "react";
import { Controller, useForm } from "react-hook-form";

// components
import {
    Button,
    DialogActions,
    DialogContent,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";

// types
import { FEEDBACK_TYPES, IFeedback } from "../../types/Feedback";

// styles
import { Action, Primary } from "../../../../styles/colors";

// helpers
import { getBrowser } from "../../helpers/Browser";

export interface IFeedbackFormProps {
    userName: string;
    onFeedbackClose: () => void;
    onFeedbackSubmit: (feedback: IFeedback) => void;
}

const FeedbackForm: React.FC<IFeedbackFormProps> = ({ userName, onFeedbackClose, onFeedbackSubmit }) => {
    const { control, reset, handleSubmit, getValues } = useForm({
        defaultValues: {
            type: "",
            title: "",
            description: "",
            userName: userName,
            browser: getBrowser(),
            screenSize: `${window.innerWidth} x ${window.innerHeight}`,
        },
    });

    const onSubmit = (feedback: IFeedback): void => {
        onFeedbackSubmit({ ...feedback });
    };

    const onCancel = (): void => {
        reset();
        onFeedbackClose();
    };

    const renderFeedbackFormType = (): React.ReactElement => {
        return (
            <FormControl fullWidth>
                <InputLabel id="type">Type</InputLabel>
                <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                        <Select {...field} label="Type">
                            {...FEEDBACK_TYPES.map(({ label, value }) => {
                                return <MenuItem value={value}>{label}</MenuItem>;
                            })}
                        </Select>
                    )}
                />
            </FormControl>
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <DialogContent dividers>
                <Grid container rowSpacing={2}>
                    <Grid item container columnSpacing={2}>
                        <Grid item flex={1}>
                            {renderFeedbackFormType()}
                        </Grid>
                        <Grid item flex={1}>
                            <Controller
                                name="title"
                                control={control}
                                render={({ field }) => <TextField required {...field} label="Title" variant="outlined" fullWidth />}
                            />
                        </Grid>
                    </Grid>
                    <Grid item flex={2}>
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    required
                                    {...field}
                                    label="FeedbackForm Description"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container p={2}>
                    <Grid item container flex={2}>
                        <Grid item>
                            <Typography variant="body2" color={Action[0]}>
                                What other information are we collecting?
                            </Typography>
                            <Typography variant="body2" color={Action.main}>
                                Username: {userName}, Browser: {getValues("browser")}, Screen size:
                                {getValues("screenSize")}
                            </Typography>
                        </Grid>
                        <Grid item></Grid>
                    </Grid>
                    <Grid item container columnSpacing={1} flex={1} justifyContent="end">
                        <Grid item>
                            <Button variant="text" sx={{ color: Action.main }} onClick={onCancel}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="text" type="submit" sx={{ color: Primary.main }}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </form>
    );
};

export default React.memo(FeedbackForm);
