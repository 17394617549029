// types
import { SxProps } from "@mui/material";

export const feedbackSubmittedContainer: SxProps = {
    rowGap: 2,
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
};

export const feedbackSubmittedItems: SxProps = {
    display: "flex",
    justifyContent: "center",
};
