// libs
import { useCallback } from "react";
import { useDispatch } from "react-redux";

// stores
import { showAlertMessage, hideAlertMessage } from "../../store/page-alert-reducer";

// constants
import { ERROR_MESSAGE, ErrorEnum } from "../../constants/Error";

// types
import { IAlert } from "../../types/Alert";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useAlertMessageHandler() {
    const dispatch = useDispatch();

    const showErrorAlertMessage = useCallback((errors): void => {
        /**
         * if ErrorType exist and equal to ErrorEnum in that case we will show the Enum specific
         * error message otherwise we will show generic Server Error message.
         */
        const errorMessage: IAlert =
            errors.length && errors[0].errorType && ERROR_MESSAGE[errors[0].errorType]
                ? ERROR_MESSAGE[errors[0].errorType]
                : ERROR_MESSAGE[ErrorEnum.SERVER_ERROR];
        dispatch(showAlertMessage({ alertMessage: errorMessage, enableRefreshCTA: false }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hideErrorAlertMessage = useCallback((): void => {
        dispatch(hideAlertMessage());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { showErrorAlertMessage, hideErrorAlertMessage };
}

export default useAlertMessageHandler;
