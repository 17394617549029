export enum FeedbackType {
    BUG_ISSUE = "Bug/issue",
    FEATURE_REQUEST = "Feature request",
    DESIGN_FEEDBACK = "Design feedback",
    OTHER = "Other",
}

export const FEEDBACK_TYPES = [
    { label: FeedbackType.BUG_ISSUE, value: FeedbackType.BUG_ISSUE },
    { label: FeedbackType.FEATURE_REQUEST, value: FeedbackType.FEATURE_REQUEST },
    { label: FeedbackType.DESIGN_FEEDBACK, value: FeedbackType.DESIGN_FEEDBACK },
    { label: FeedbackType.OTHER, value: FeedbackType.OTHER },
];

export interface IFeedback {
    type: FeedbackType;
    title: string;
    description: string;
    userName: string;
    browser: string;
    screenSize: string;
}
