import { IAllocationType } from "../types/utilities";
import { AllocationType, InputType } from "./appsync/schema/API";

export const ALLOCATION_TYPES: Array<IAllocationType> = [
    {
        id: 0,
        label: "Opex Allocations",
        key: AllocationType.opex_allocation,
    },
];

export const INPUT_TYPES: Array<IAllocationType> = [
    {
        id: 0,
        label: "Definitions",
        key: InputType.allocation_definition,
    },
    {
        id: 1,
        label: "Mappings",
        key: InputType.allocation_mapping,
    },
];
