// libss
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useCallback, useEffect } from "react";

// stores
import { startLoading, stopLoading } from "../../../../../store/loader-reducer";

// services
import { getActiveInputList } from "../../../../../services";

// components
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Typography,
    Checkbox,
    Chip,
    Grid,
} from "@mui/material";
import { ExpandCircleDown } from "@mui/icons-material";
import InputChangesTable from "./InputChangesTable/InputChangesTable";

// utils
import { DateRenderer } from "../../../../../components/renderers/DateRenderer";
import { InputChangeSelectorModeProvider } from "./InputChangeSelectorProvider";

// types
import { AllocationType, InputType, UserSandboxAccess } from "../../../../../utils/appsync/schema/API";

// hooks
import { useAlertMessageHandler } from "../../../../../hooks/useErrorMessageHandler";

// styles
import { inputChangeSelectorMenu } from "./InputChangeSelector.style";

interface IInputChangeSelectorProps {
    fieldLabel: string;
    currentValue: string;
    inputType: InputType;
    allocationType: AllocationType;
    onChange?: any;
    readonly?: boolean;
}

const InputChangeSelector: React.FC<IInputChangeSelectorProps> = ({
    allocationType,
    inputType,
    fieldLabel,
    onChange,
    currentValue,
    readonly,
}: IInputChangeSelectorProps) => {
    const [open, setOpen] = useState(!!currentValue);
    const [activeInputs, setActiveInputs] = useState<Array<any>>([]);
    // eslint-disable-next-line no-unused-vars
    const [nextToken, setNextToken] = useState<string>("");
    const [selectedInputChange, setSelectedInputChange] = useState(currentValue);
    const [inputValue, setInputValue] = useState("");
    const [enableSelection, setEnableSelection] = useState(!!currentValue);
    const selectedSandbox: UserSandboxAccess = useSelector((state: any) => state.userInfo.selectedSandbox);
    const dispatch = useDispatch();

    const { showErrorAlertMessage } = useAlertMessageHandler();

    const [diffDataCount, setDiffDataCount] = useState<number>(0);

    const fetchInputs = useCallback(
        async (filters: Array<string>, limit: number, offset: number) => {
            if (selectedSandbox?.sandbox_id) {
                dispatch(startLoading());
                try {
                    const activeInputListResponse = await getActiveInputList(
                        selectedSandbox.sandbox_id,
                        allocationType,
                        inputType,
                        limit,
                        offset,
                        filters,
                    );
                    setActiveInputs(activeInputListResponse?.items || []);
                    setNextToken(activeInputListResponse?.nextToken || "");
                } catch ({ errors }) {
                    showErrorAlertMessage(errors);
                }
                dispatch(stopLoading());
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedSandbox, allocationType, inputType],
    );

    useEffect(() => {
        setSelectedInputChange(currentValue);
        setEnableSelection(!!currentValue);
    }, [currentValue]);

    const toggleAccordion = (): void => {
        if (!open) {
            if (selectedInputChange) {
                setOpen(true);
            }
        } else {
            setOpen(false);
        }
    };

    const selectItemHandler = (evt: any): void => {
        evt.stopImmediatePropagation();
        selectItem(evt.target.value);
    };

    const selectItem = (value: any): void => {
        const valueChanged = selectedInputChange !== value;
        setSelectedInputChange(value);
        onChange(value);
        // Hack to avoid accordion from closing
        setTimeout(() => {
            valueChanged && setOpen(!!value);
        }, 500);
    };

    const inputSelectedTrigger = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        setEnableSelection(!!evt?.target.checked);
        if (!evt?.target.checked) {
            setInputValue(selectedInputChange);
            selectItem("");
        } else {
            setOpen(!!inputValue);
            selectItem(inputValue);
        }
    };

    useEffect(() => {
        fetchInputs([""], 20, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSandbox]);

    return (
        <InputChangeSelectorModeProvider>
            <Accordion expanded={open} onChange={toggleAccordion}>
                <AccordionSummary expandIcon={<ExpandCircleDown />}>
                    <FormControl disabled={!!readonly}>
                        <Checkbox checked={!!enableSelection} onChange={inputSelectedTrigger} />
                    </FormControl>
                    <FormControl disabled={!!readonly || !enableSelection} variant="filled" sx={{ width: "100%" }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <InputLabel id={`select-label-${allocationType}-${inputType}`} size="small">
                                    Select {fieldLabel}
                                </InputLabel>
                                <Select
                                    disabled={!!readonly}
                                    label={`Select ${fieldLabel}`}
                                    labelId={`select-label-${allocationType}-${inputType}`}
                                    value={selectedInputChange}
                                    onChange={selectItemHandler}
                                    size="small"
                                    sx={inputChangeSelectorMenu}>
                                    <MenuItem value={""}>{/* Production Version */}</MenuItem>
                                    {activeInputs?.map((inputItem) => (
                                        <MenuItem key={inputItem.resource_key} value={inputItem.resource_key}>
                                            <Typography paddingRight={1}>{inputItem.userAlias}</Typography>
                                            <DateRenderer timestamp={inputItem.created_at} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            {diffDataCount > 0 && (
                                <Grid item>
                                    <Chip label={`${diffDataCount} ${fieldLabel}`} variant="outlined" sx={{ mx: 1 }} />
                                </Grid>
                            )}
                        </Grid>
                    </FormControl>
                </AccordionSummary>
                <AccordionDetails>
                    {selectedInputChange && (
                        <InputChangesTable
                            input_id={selectedInputChange}
                            allocationType={allocationType}
                            inputType={inputType}
                            onFetchDiffDataCallback={(diffData) => {
                                diffData && setDiffDataCount(JSON.parse(diffData.diff).length);
                            }}
                        />
                    )}
                </AccordionDetails>
            </Accordion>
        </InputChangeSelectorModeProvider>
    );
};

export default InputChangeSelector;
