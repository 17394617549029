import React, { useCallback } from "react";
import { Button, ButtonGroup, Grid, Stack, Typography } from "@mui/material";

const BusinessInclusionsButtonGroup = ({ items, prefixKey }) => {
    return (
        <ButtonGroup variant="text" size="small">
            {items?.map((item) => (
                <Button key={prefixKey + "-" + item}>{item}</Button>
            ))}
        </ButtonGroup>
    );
};

const BusinessInclusionsRenderer = ({ businessGroups, indx }) => {
    const splitList = useCallback(() => {
        if (/[[\],]+/.test(businessGroups)) {
            return businessGroups
                ?.split(";")
                ?.filter((v) => !!v)
                ?.map((v) => ({
                    name: v.split(".")?.[0],
                    values: v
                        .split(".")?.[1]
                        ?.replace("[", "")
                        ?.replace("]", "")
                        ?.split(",")
                        ?.filter((x) => !!x),
                }));
        } else {
            return businessGroups?.split(";")?.filter((v) => !!v);
        }
    }, [businessGroups]);

    if (/[[\],]+/.test(businessGroups)) {
        return (
            <Grid>
                {splitList()?.map((itemName) => (
                    <Stack key={indx + "-" + itemName.name + "-" + Math.random()}>
                        <Typography>{itemName.name}</Typography>
                        <BusinessInclusionsButtonGroup items={itemName.values} prefixKey={indx + "-" + itemName.name} />
                    </Stack>
                ))}
            </Grid>
        );
    } else {
        return <BusinessInclusionsButtonGroup items={splitList() ?? []} prefixKey={indx + "-"} />;
    }
};

export default BusinessInclusionsRenderer;
