// libs
import { styled } from "@mui/material";

// components
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

// colors
import { Primary } from "../../../../styles/colors";

const drawerWidth = "18rem";

// props
interface IAppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<IAppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth})`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    backgroundColor: Primary.white,
}));

export default AppBar;
