import { GridColDef } from "@mui/x-data-grid-pro";
import { formatMillisecondsToDate } from "../../../utils/control-plane";

export const SANDBOXES_LIST_COLUMNS: Array<GridColDef<any>> = [
    {
        field: "sandbox_id",
        headerName: "ID",
        width: 300,
    },
    {
        field: "sandbox_name",
        headerName: "Name",
        width: 250,
    },
    {
        field: "resource_id",
        headerName: "Bindle Resource ID",
        width: 350,
    },
    {
        field: "owner_bindle_id",
        headerName: "Bindle Owner",
        width: 350,
    },
    {
        field: "created_at",
        headerName: "Created at",
        width: 350,
        valueFormatter: (field?: any) => formatMillisecondsToDate(field.value),
    },
];

export const SANDBOXES_LIST_FILTER_FIELDS: Array<string> = ["sandbox_id", "sandbox_name", "resource_id", "owner_bindle_id"];

export const SANDBOXES_LIST_PAGE_SIZE = 20;
