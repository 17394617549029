const Info = {
    /**The token represents the info.main token */
    main: "#0071B3",
    /**The token represent the white color*/
    white: "#ffffff",
    /**The token represents 95% lighter shade of the info.white token */
    95: "#E6F6FF",
    /**The token represents 90% lighter shade of the info.white token */
    90: "#CCECFF",
    /**The token represents 85% lighter shade of the info.white token */
    85: "#B3E3FF",
    /**The token represents 80% lighter shade of the info.white token */
    80: "#99DAFF",
    /**The token represents 75% lighter shade of the info.white token */
    75: "#80D0FF",
    /**The token represents 70% lighter shade of the info.white token */
    70: "#66C7FF",
    /**The token represents 65% lighter shade of the info.white token */
    65: "#4DBEFF",
    /**The token represents 60% lighter shade of the info.white token */
    60: "#33B4FF",
    /**The token represents 55% lighter shade of the info.white token */
    55: "#1AABFF",
    /**The token represents 50% lighter shade of the info.white token */
    50: "#00A3FF",
    /**The token represents 45% lighter shade of the info.white token */
    45: "#0091E6",
    /**The token represents 40% lighter shade of the info.white token */
    40: "#0081CC",
    /**The token represents 35% lighter shade of the info.white token */
    35: "#0071B3",
    /**The token represents 30% lighter shade of the info.white token */
    30: "#006199",
    /**The token represents 25% lighter shade of the info.white token */
    25: "#005180",
    /**The token represents 20% lighter shade of the info.white token */
    20: "#004166",
    /**The token represents 15% lighter shade of the info.white token */
    15: "#00304D",
    /**The token represents 10% lighter shade of the info.white token */
    10: "#002033",
    /**The token represents 5% lighter shade of the info.white token */
    5: "#00101A",
    /**The token represents 0% lighter shade of the info.white token */
    0: "#000000",
};

export default Info;
