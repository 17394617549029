// libs
import React from "react";

// components
import { Button, DialogContent, Grid, Link, Typography } from "@mui/material";

// icons
import { CheckCircleOutlined } from "@mui/icons-material";

// styles
import { feedbackSubmittedContainer, feedbackSubmittedItems } from "./FeedbackSubmitted.style";

export interface IFeedbackSubmittedProps {
    submittedUrl: string;
    onFeedbackClose: VoidFunction;
}

const FeedbackSubmitted: React.FC<IFeedbackSubmittedProps> = ({ submittedUrl, onFeedbackClose }) => {
    return (
        <DialogContent dividers>
            <Grid container sx={feedbackSubmittedContainer}>
                <Grid item sx={feedbackSubmittedItems}>
                    <CheckCircleOutlined fontSize="large" color="success" />
                </Grid>
                <Grid item sx={feedbackSubmittedItems}>
                    <Typography variant="h6">Feedback Submitted</Typography>
                </Grid>
                <Grid item sx={feedbackSubmittedItems}>
                    <Typography variant="body2">Thank you for the feedback! Track updates and progress through this </Typography>
                    <Link variant="body2" href={submittedUrl} px="4px" target="_blank" rel="noopener noreferrer">
                        ticket.
                    </Link>
                </Grid>
                <Grid item sx={feedbackSubmittedItems}>
                    <Button size="small" variant="outlined" onClick={onFeedbackClose}>
                        Close
                    </Button>
                </Grid>
            </Grid>
        </DialogContent>
    );
};

export default React.memo(FeedbackSubmitted);
