// libs
import React from "react";

// components
import { Grid, Typography } from "@mui/material";

const A3SIntro: React.FC = () => {
    return (
        <Grid item md={8} sx={{ px: 8 }}>
            <Typography variant="body1">Corp FP&A</Typography>
            <Typography variant="h4" sx={{ marginTop: "36px" }}>
                A3S: Allocations as a Service
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: "24px" }}>
                A self-service Simulation platform
            </Typography>
            <Typography variant="subtitle2">
                Offering over 11 sandboxes, empowering finance users across FGBS to simulate allocation rule changes in any corporate
                FP&A-owned allocations. A3S enables users to quantify the impact of their simulations at any time, enhancing the
                decision-making process and optimize cost allocation for Amazon.
            </Typography>
        </Grid>
    );
};

export default React.memo(A3SIntro);
