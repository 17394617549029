const Warning = {
    /**The token represents the warning.main token */
    main: "#FFD600",
    /**The token represent the white color*/
    white: "#ffffff",
    /**The token represents 95% lighter shade of the warning.white token */
    95: "#FFFBE6",
    /**The token represents 90% lighter shade of the warning.white token */
    90: "#FFF6CC",
    /**The token represents 85% lighter shade of the warning.white token */
    85: "#FFF2B3",
    /**The token represents 80% lighter shade of the warning.white token */
    80: "#FFEE99",
    /**The token represents 75% lighter shade of the warning.white token */
    75: "#FFEA80",
    /**The token represents 70% lighter shade of the warning.white token */
    70: "#FFE666",
    /**The token represents 65% lighter shade of the warning.white token */
    65: "#FFE14D",
    /**The token represents 60% lighter shade of the warning.white token */
    60: "#FFDD33",
    /**The token represents 55% lighter shade of the warning.white token */
    55: "#FFD91A",
    /**The token represents 50% lighter shade of the warning.white token */
    50: "#FFD600",
    /**The token represents 45% lighter shade of the warning.white token */
    45: "#E6BF00",
    /**The token represents 40% lighter shade of the warning.white token */
    40: "#CCAA00",
    /**The token represents 35% lighter shade of the warning.white token */
    35: "#B39500",
    /**The token represents 30% lighter shade of the warning.white token */
    30: "#998000",
    /**The token represents 25% lighter shade of the warning.white token */
    25: "#806A00",
    /**The token represents 20% lighter shade of the warning.white token */
    20: "#665500",
    /**The token represents 15% lighter shade of the warning.white token */
    15: "#4D4000",
    /**The token represents 10% lighter shade of the warning.white token */
    10: "#332B00",
    /**The token represents 5% lighter shade of the warning.white token */
    5: "#1A1500",
    /**The token represents 0% lighter shade of the warning.white token */
    0: "#000000",
};

export default Warning;
