// libs
import React from "react";
import ReactDOM from "react-dom/client";

// components
import App from "./App";

// styles
import { theme } from "./styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// constants
import reportWebVitals from "./reportWebVitals";
import { LicenseInfo } from "@mui/x-license-pro";

// MUI license
LicenseInfo.setLicenseKey("21df911451adf7f6504fb4ac86f44ccbTz03OTgwOSxFPTE3MzMwNjU5MjQwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <App />
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
