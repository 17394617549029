// libs
import { useDispatch } from "react-redux";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

// utils
import { getOperatorFromDataGridToApi } from "../utils";
import { getClient, graphQlMutations, graphQlQueries } from "../utils/appsync";
import { Sim, CreateSimRequest, SimListPage, UpdateSimRequest } from "../utils/appsync/schema/API";

// stores
import { startLoading, stopLoading } from "../store/loader-reducer";

// defs
import { GridFilterModel } from "@mui/x-data-grid-pro";

export const useGetSimulationList = (
    sandbox_id: string,
    limit: number = 100,
    filterModel?: GridFilterModel,
    nextToken?: string,
): UseQueryResult<Array<Sim>, Error> => {
    const dispatch = useDispatch();

    return useQuery<Array<Sim>, Error>({
        queryKey: ["sandboxList", sandbox_id, filterModel],
        queryFn: async () => {
            const simulationItems: Array<Sim> = [];
            let filters: any | undefined;

            dispatch(startLoading());

            if (filterModel?.items?.length) {
                filters = {};
                filterModel.items.forEach((filterItem: any) => {
                    if (filterItem.field && filterItem.value) {
                        filters[filterItem.field] = {
                            [getOperatorFromDataGridToApi(filterItem.operator)]: filterItem.value,
                        };
                    }
                });
            }

            const initialiseSimuationList = (simListPage: SimListPage): Array<Sim> => {
                if (simListPage.items) {
                    return simListPage.items.filter((val: Sim | null) => val != null).map((val: Sim) => val!!);
                }

                return [];
            };

            try {
                const { data }: GraphQLResult<any> = await getClient().graphql({
                    query: graphQlQueries.ListSimsBySandbox,
                    variables: {
                        sandbox_id,
                        is_active: true,
                        limit,
                        nextToken,
                        filters,
                    },
                });

                simulationItems.push(...initialiseSimuationList(data.ListSimsBySandbox));
            } catch ({ data }) {
                if (data?.ListSimsBySandbox) {
                    simulationItems.push(...initialiseSimuationList(data.ListSimsBySandbox));
                }
            }

            dispatch(stopLoading());

            return simulationItems;
        },
        enabled: !!sandbox_id,
    });
};

export const useGetSimulation = (sandbox_id: string, sim_id: string): UseQueryResult<Sim, Error> => {
    const dispatch = useDispatch();

    return useQuery<Sim, Error>({
        queryKey: ["simulation", sandbox_id, sim_id],
        queryFn: async () => {
            dispatch(startLoading());

            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlQueries.GetSimDetails,
                variables: {
                    sandbox_id,
                    sim_id,
                    is_active: true,
                },
            });

            dispatch(stopLoading());

            return data.GetSimDetails;
        },
        enabled: !!sandbox_id && !!sim_id?.length,
    });
};

export const useCreateSimulation = (): UseMutationResult<Sim, Error, { sandbox_id: string; request: CreateSimRequest }> => {
    return useMutation<Sim, Error, { sandbox_id: string; sim_id: string; request: CreateSimRequest }>({
        mutationFn: async ({ sandbox_id, sim_id, request }) => {
            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlMutations.CreateSim,
                variables: {
                    request,
                    sandbox_id,
                },
            });
            return data.CreateSim;
        },
    });
};

export const useUpdateSimulation = (): UseMutationResult<Sim, Error, { sandbox_id: string; sim_id: string; request: UpdateSimRequest }> => {
    return useMutation<Sim, Error, { sandbox_id: string; sim_id: string; request: UpdateSimRequest }>({
        mutationFn: async ({ sandbox_id, sim_id, request }) => {
            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlMutations.UpdateSim,
                variables: {
                    request,
                    sandbox_id,
                    sim_id,
                },
            });
            return data.UpdateSim;
        },
    });
};

export const useSaveSimulation = (): UseMutationResult<
    Sim,
    Error,
    { sandbox_id: string; sim_id: string; request: CreateSimRequest | UpdateSimRequest }
> => {
    return useMutation<Sim, Error, { sandbox_id: string; sim_id: string; request: CreateSimRequest | UpdateSimRequest }>({
        mutationFn: async ({ sandbox_id, sim_id, request }) => {
            if (sim_id) {
                const { data }: GraphQLResult<any> = await getClient().graphql({
                    query: graphQlMutations.UpdateSim,
                    variables: {
                        request,
                        sandbox_id,
                        sim_id,
                    },
                });
                return data.UpdateSim;
            }

            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlMutations.CreateSim,
                variables: {
                    request,
                    sandbox_id,
                },
            });
            return data.CreateSim;
        },
    });
};

export const useSubmitSimulation = (): UseMutationResult<Sim, Error, { sandbox_id: string; sim_id: string; userAlias: string }> => {
    return useMutation<Sim, Error, { sandbox_id: string; sim_id: string; userAlias: string }>({
        mutationFn: async ({ sandbox_id, sim_id, userAlias }) => {
            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlMutations.StartSim,
                variables: {
                    sandbox_id,
                    sim_id,
                    userAlias,
                },
            });
            return data.StartSim;
        },
    });
};

export const useCancelSimulation = (): UseMutationResult<Sim, Error, { sandbox_id: string; sim_id: string; userAlias: string }> => {
    return useMutation<Sim, Error, { sandbox_id: string; sim_id: string; userAlias: string }>({
        mutationFn: async ({ sandbox_id, sim_id, userAlias }) => {
            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlMutations.CancelSim,
                variables: {
                    sandbox_id,
                    sim_id,
                    userAlias,
                },
            });
            return data.CancelSim;
        },
    });
};

export const useDeleteSimulation = (): UseMutationResult<
    Sim,
    Error,
    { sandbox_id: string; sim_id: string; is_shared: boolean; userAlias: string }
> => {
    return useMutation<Sim, Error, { sandbox_id: string; sim_id: string; is_shared: boolean; userAlias: string }>({
        mutationFn: async ({ sandbox_id, sim_id, is_shared, userAlias }) => {
            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlMutations.DeprecateSim,
                variables: {
                    sandbox_id,
                    sim_id,
                    is_shared,
                    userAlias,
                },
            });
            return data.DeprecateSim;
        },
    });
};

export const useGetEmbedUrlForSimulationReport = (user_id: string, sim_id: string): UseQueryResult<string, Error> => {
    return useQuery<string, Error>({
        queryKey: ["embedUrlForSimuationReport", user_id, sim_id],
        queryFn: async () => {
            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlQueries.GetReportEmbedUrl,
                variables: {
                    user_id,
                    sim_id,
                },
            });

            return data.GetReportEmbedUrl;
        },
        enabled: !!user_id && !!sim_id?.length,
    });
};
