// libs
import React, { useMemo } from "react";

// components
import { Typography } from "@mui/material";

// utils
import { parse, format } from "date-fns";

type DateRendererProps = {
    timestamp?: string;
};

export const convertDateTime = (timestamp?: string): string => {
    if (timestamp) {
        return format(parse(timestamp + "", "t", new Date()), "MM/dd/yyyy hh:mm a");
    }
    return "";
};

export const DateRenderer = (props: DateRendererProps): React.ReactElement => {
    const parsedDateTime = useMemo(() => {
        return convertDateTime(props.timestamp);
    }, [props.timestamp]);

    return <Typography>{parsedDateTime}</Typography>;
};
