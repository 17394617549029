// colors
import { Primary } from "../../../../styles/colors";

export const pageTitleBarTextField = {
    minWidth: "25rem",
    borderRadius: "4px",
    background: Primary.white,

    ".Mui-disabled": {
        pointerEvents: "none",
    },

    ".MuiOutlinedInput-notchedOutline": {
        borderColor: `${Primary.white} !important`,
    },
};
