// colors
import { Error, Primary, Secondary, Success, Warning, Info } from "../colors";

export const MuiChipComponent = {
    styleOverrides: {
        colorPrimary: {
            color: Primary.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Primary[95],
            textTransform: "capitalize" as const,
        },
        colorSecondary: {
            color: Secondary.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Secondary[95],
            textTransform: "capitalize" as const,
        },
        colorWarning: {
            color: Warning.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Warning[95],
            textTransform: "capitalize" as const,
        },
        colorInfo: {
            color: Info.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Info[95],
            textTransform: "capitalize" as const,
        },
        colorError: {
            color: Error.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Error[95],
            textTransform: "capitalize" as const,
        },
        colorSuccess: {
            color: Success.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Success[95],
            textTransform: "capitalize" as const,
        },
        outlined: {
            padding: "3px 4px",
            borderRadius: "16px",
            textTransform: "capitalize" as const,
        },
        outlinedPrimary: {
            color: Primary.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Primary.white,
            border: `1px solid ${Primary.main}`,
            textTransform: "capitalize" as const,
        },
        outlinedSecondary: {
            color: Secondary.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Secondary.white,
            border: `1px solid ${Secondary.main}`,
            textTransform: "capitalize" as const,
        },
        outlinedWarning: {
            color: Warning.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Warning.white,
            border: `1px solid ${Warning.main}`,
            textTransform: "capitalize" as const,
        },
        outlinedInfo: {
            color: Info.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Info.white,
            border: `1px solid ${Info.main}`,
            textTransform: "capitalize" as const,
        },
        outlinedError: {
            color: Error.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Error.white,
            border: `1px solid ${Error.main}`,
            textTransform: "capitalize" as const,
        },
        outlinedSuccess: {
            color: Success.main,
            padding: "3px 4px",
            borderRadius: "16px",
            backgroundColor: Success.white,
            border: `1px solid ${Success.main}`,
            textTransform: "capitalize" as const,
        },
    },
};
