// libs
import React, { useState } from "react";

// components
import { InputChangesTable } from "../InputChangeSelector/InputChangesTable";
import { DateRenderer } from "../../../../../components/renderers/DateRenderer";
import { InputChangeSelectorModeProvider } from "../InputChangeSelector/InputChangeSelectorProvider";
import { Grid, Accordion, AccordionDetails, AccordionSummary, FormControl, Chip, Typography } from "@mui/material";

// icons
import { ExpandCircleDown } from "@mui/icons-material";

// schema
import { InputDictionaryDiff } from "../../../../../utils/appsync/schema/API";

// styles
import { Action } from "../../../../../styles/colors";
import { simulationInputHeader, simulationInputHeaderSubtitle } from "./SimulationInput.style";

// types
import { IAllocationType, IInputType } from "../../../../../types/utilities";

// props
interface ISimulationInputProps {
    inputType: IInputType;
    selectedInput: string;
    allocationType: IAllocationType;
}

const SimulationInput: React.FC<ISimulationInputProps> = ({ allocationType, inputType, selectedInput }): React.ReactElement => {
    const [open, setOpen] = useState(false);

    const [diffData, setDiffData] = useState<InputDictionaryDiff>(undefined);

    const toggleAccordion = (): void => {
        setOpen(!open);
    };

    const renderSimulationInputHeader = (): React.ReactElement => {
        if (!diffData) {
            return <></>;
        }

        return (
            <React.Fragment>
                <Grid item sx={simulationInputHeader}>
                    <Typography variant="caption" color={Action.main}>{`${inputType.label} Changes`}</Typography>
                    <Typography variant="subtitle1" sx={simulationInputHeaderSubtitle}>
                        {diffData.userAlias}
                        <DateRenderer timestamp={String(diffData.created_at)} />
                    </Typography>
                </Grid>
                <Grid item>
                    <Chip label={`${JSON.parse(diffData.diff).length} ${inputType.label}`} variant="outlined" sx={{ mx: 1 }} />
                </Grid>
            </React.Fragment>
        );
    };

    return (
        <InputChangeSelectorModeProvider>
            <Accordion expanded={open} onChange={toggleAccordion}>
                <AccordionSummary expandIcon={<ExpandCircleDown />}>
                    <FormControl disabled={true} sx={{ width: "100%" }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            {renderSimulationInputHeader()}
                        </Grid>
                    </FormControl>
                </AccordionSummary>
                <AccordionDetails>
                    <InputChangesTable
                        key={allocationType.key + "#" + inputType.key}
                        input_id={selectedInput}
                        allocationType={allocationType.key}
                        inputType={inputType.key}
                        onFetchDiffDataCallback={(diffData) => {
                            setDiffData(diffData);
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </InputChangeSelectorModeProvider>
    );
};

export default React.memo(SimulationInput);
