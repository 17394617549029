// types
import { SxProps } from "@mui/material";

// colors
import { Action } from "../../../../../styles/colors";

export const simulationInputHeader: SxProps = {
    p: 1,
    backgroundColor: Action.background[40],
    borderBottom: `1px solid ${Action[85]}`,
};

export const simulationInputHeaderSubtitle: SxProps = {
    display: "flex",
    columnGap: "4px",
};
