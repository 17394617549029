import { createSlice } from "@reduxjs/toolkit";

export type PageTitleCrumb = {
    icon?: string;
    path?: string;
    title?: string;
};

export type PageTitleState = {
    title?: string;
    icon?: string;
    crumbs?: Array<PageTitleCrumb>;
    endToEnd?: boolean;
};

export const pageTitleSlice = createSlice({
    name: "pageTitle",
    initialState: {
        title: "",
        icon: "",
        endToEnd: false,
    },
    reducers: {
        setPageTitle: (state: PageTitleState, action: { payload: PageTitleState }) => {
            state.title = action.payload.title;
            state.icon = action.payload.icon;
            state.crumbs = action.payload.crumbs;
            state.endToEnd = !!action.payload.endToEnd;
        },
    },
});

export const { setPageTitle } = pageTitleSlice.actions;

export default pageTitleSlice.reducer;
