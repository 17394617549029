// libs
import React, { useEffect, useState } from "react";

// components
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Switch, Typography } from "@mui/material";

// styles
import { allCostPool, allCostPoolSwitchDescription } from "./AllCostPool.style";

export interface IAllCostPoolProps {
    readOnly: boolean;
    isAllCostPoolSelected: boolean;
    onAllCostPoolSelected?: (isAllCostPoolSelected: boolean) => void;
}

const AllCostPool: React.FC<IAllCostPoolProps> = ({ readOnly, isAllCostPoolSelected, onAllCostPoolSelected }) => {
    const [allCostPoolSelected, setAllCostPoolSelected] = useState(isAllCostPoolSelected);

    const [showConfirmationDialog, toggleConfirmationDialog] = useState(false);

    useEffect(() => {
        setAllCostPoolSelected(isAllCostPoolSelected);
    }, [isAllCostPoolSelected]);

    const onAllCostPoolSelectedEvent = (event: React.ChangeEvent<HTMLInputElement>): void => {
        // in case of true i.e. Select All cost pool, prompting confirmation dialog
        // to the user.
        if (event.target.checked) {
            toggleConfirmationDialog(true);
        } else {
            setAllCostPoolSelected(false);
            onAllCostPoolSelected && onAllCostPoolSelected(false);
        }
    };

    const renderConfirmationDialog = (): React.ReactElement => {
        const onConfirmationDialogCancel = (): void => {
            toggleConfirmationDialog(false);
        };

        const onConfirmationDialogProceed = (): void => {
            setAllCostPoolSelected(true);
            onAllCostPoolSelected && onAllCostPoolSelected(true);
            toggleConfirmationDialog(false);
        };

        return (
            <Dialog
                open={showConfirmationDialog}
                onClose={onConfirmationDialogCancel}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description">
                <DialogTitle id="confirmation-dialog-title">Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        The simulation will require processing large datasets that will take up to 4hrs to complete.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmationDialogCancel}>No, Cancel</Button>
                    <Button onClick={onConfirmationDialogProceed} autoFocus>
                        Yes, Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <React.Fragment>
            {renderConfirmationDialog()}
            <Grid container flexDirection="row" sx={allCostPool}>
                <Grid item>
                    <Switch disabled={readOnly} checked={allCostPoolSelected} onChange={onAllCostPoolSelectedEvent} />
                </Grid>
                <Grid item pt={0.5}>
                    <Typography variant="body1">Total Amazon</Typography>
                    <Typography variant="body2" sx={allCostPoolSwitchDescription}>
                        Include all costpools
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default React.memo(AllCostPool);
