// libs
import React, { useEffect, useRef, useState, useMemo, forwardRef, useImperativeHandle } from "react";

// components
import { HotTable } from "@handsontable/react";
import { Loader } from "../../../../components/Loader";
import { CustomEditor } from "../../../../components/renderers/CustomEditor";

// servies
import { useGetAllocationConfig, useGetAllocationConfigSchema } from "../../../../services/AllocationConfigService";

// styles
import styles from "./Table.module.scss";
// eslint-disable-next-line import/no-unassigned-import
import "handsontable/dist/handsontable.full.min.css";

// defs
import { AllocationConfigSchemaColumn, AllocationType, InputType } from "../../../../utils/appsync/schema/API";

// props
export interface IAllocationConfigTable {
    inputType: string;
    allocationType: AllocationType;
    onUpdateSelectedContent: (selectedContent: string) => void;
    onUpdateLastModifiedDate: (lastModifiedDate: string) => void;
}

const AllocationConfigTable = forwardRef<HotTable, IAllocationConfigTable>(
    ({ inputType, allocationType, onUpdateSelectedContent, onUpdateLastModifiedDate }: IAllocationConfigTable, ref) => {
        const offset = 0;
        const allocationPoolFilters = [""];

        const [columnsSchema, setColumnsSchema] = useState([]);

        const [tableData, setTableData] = useState([]);

        const hotRef = useRef(null);

        useImperativeHandle(ref, () => hotRef.current as HotTable);

        const allocationConfig = useGetAllocationConfig(allocationType, inputType as InputType, 20000, offset, allocationPoolFilters);

        const allocationConfigSchema = useGetAllocationConfigSchema(allocationType, inputType as InputType);

        useEffect(() => {
            hotRef?.current?.hotInstance?.loadData?.(tableData);
        }, [hotRef, tableData]);

        useEffect(() => {
            if (hotRef?.current) {
                // reset the scroll on update of Input type or allocation Type
                hotRef?.current?.hotInstance.scrollViewportTo(0, 0);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [inputType, allocationType]);

        useEffect(() => {
            const updateData = (allocationsResponse): void => {
                onUpdateLastModifiedDate("");
                if (!allocationsResponse) {
                    return;
                }
                onUpdateLastModifiedDate(allocationsResponse.last_modified);
                setTableData(
                    allocationsResponse.content?.map((v, i) => {
                        return [i + 1, ...v.map((vv) => (!vv ? " " : vv))];
                    }),
                );
            };

            if (allocationConfigSchema.data?.column_schema) {
                setColumnsSchema(allocationConfigSchema.data.column_schema);
            }

            if (allocationConfig.data) {
                updateData(allocationConfig.data);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [allocationConfig.data, allocationConfigSchema.data]);

        const columnSettings = useMemo(() => {
            const commonFields = {
                readOnly: true,
                editor: CustomEditor,
            };

            const columns = columnsSchema.map((columnSchema: AllocationConfigSchemaColumn) => {
                // NOTE: Since custome renderes are causing performance issues
                // when rendering the 1K rows at the table, for the time being and
                // until further investigationa about how to mitigate this issue,
                // the custom renderers are going to be disabled. Thus, the custom
                // renderers below are commented out.

                // if (/(cost\s?center)|(product)|(channel)/gi.test(v)) {
                //     return {
                //         ...commonFields,
                //         renderer: costCenterRenderer.name,
                //     };
                // }

                // if (v.toLowerCase().indexOf("business inclusions") > -1) {
                //     return {
                //         ...commonFields,
                //         renderer: businessInclusionsRenderer.name
                //     }
                // }

                // if (v.toLowerCase().indexOf("request reasons") > -1) {
                //     return {
                //         ...commonFields,
                //         renderer: businessInclusionsRenderer.name
                //     }
                // }
                // return commonFields;
                return {
                    ...commonFields,
                };
            });

            // adding extra column settings for serial column
            return [{ readOnly: true }, ...columns];
        }, [columnsSchema]);

        const columnWidths = useMemo(() => {
            /**
             * TODO: currently we are hardcoding the column width on frontend side but overall column
             * level customization like hiding, width etc will come from backend.
             */
            const widths = columnsSchema.map((columnSchema: AllocationConfigSchemaColumn) => {
                if (/(driver_id)/gi.test(columnSchema.name)) {
                    return 120;
                }
                if (/(channel)/gi.test(columnSchema.name)) {
                    return 200;
                }
                if (/(effective_date_end)|(effective_date_start)/gi.test(columnSchema.name)) {
                    return 180;
                }
                if (/(product)|(business_inclusions)/gi.test(columnSchema.name)) {
                    return 370;
                }
                if (inputType === "allocation_definition" && /(cost_center)/gi.test(columnSchema.name)) {
                    return 370;
                }

                return 220;
            });

            // adding extra 40 or 55 width based upon input type  for serial column
            const serialColWidth = inputType === "allocation_definition" ? 40 : 55;

            return [serialColWidth, ...widths];
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [columnsSchema]);

        const columnHeaders = useMemo(() => {
            return [
                "",
                ...columnsSchema.map((columnSchema: AllocationConfigSchemaColumn) => {
                    return columnSchema.label;
                }),
            ];
        }, [columnsSchema]);

        // eslint-disable-next-line no-unused-vars
        const hiddenColumns = useMemo(() => {
            const hiddenColumnIndexs = [];

            columnsSchema.forEach((columnSchema: AllocationConfigSchemaColumn, index: number) => {
                // hidding the definition id column
                if (columnSchema.name === "definition_id") {
                    // adding extra 1, because of serial column.
                    hiddenColumnIndexs.push(index + 1);
                }
            });

            return hiddenColumnIndexs;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [columnsSchema]);

        const onRowSelection = (rowNo: number, columnNo: number): void => {
            if (rowNo > -1 && columnNo > -1) {
                const selectedDataCell = hotRef?.current?.hotInstance?.getDataAtCell(rowNo, columnNo);
                onUpdateSelectedContent(selectedDataCell.trim ? selectedDataCell.trim() : "");
            }
        };

        if (allocationConfig.isFetching || allocationConfigSchema.isFetching) {
            return <Loader />;
        }

        return (
            <HotTable
                ref={hotRef}
                data={tableData}
                rowHeaders={false}
                filters={true}
                dropdownMenu={true}
                // contextMenu={true}
                colHeaders={columnHeaders}
                columns={columnSettings}
                height="100%"
                colWidths={columnWidths}
                viewportRowRenderingOffset={100}
                rowHeights={30}
                manualRowResize={true}
                autoWrapRow={false}
                autoWrapCol={false}
                renderAllColumns={true}
                viewportColumnRenderingOffset={10}
                className={styles.allocationConfigTable}
                licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                afterSelection={onRowSelection}
                hiddenColumns={{
                    columns: hiddenColumns,
                    indicators: true,
                }}
            />
        );
    },
);

export default React.memo(AllocationConfigTable);
