// libs
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

// store
import { setPageTitle } from "../../../store/page-title-reducer";

// components
import { Grid } from "@mui/material";
import { GridFilterModel } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { DataGridPremium } from "@mui/x-data-grid-premium";

// services
import { useGetSandboxList } from "../../../services/SandboxService";

// constants
import { SANDBOXES_LIST_COLUMNS, SANDBOXES_LIST_PAGE_SIZE } from "./constants";

const SandboxesList: React.FC = () => {
    const dispatch = useDispatch();

    const [sandboxListFilterOptions, setSandboxListFilterOptions] = useState<any>({});

    const username: string = useSelector((state: any) => state.userInfo.username);

    const { data: sandboxList } = useGetSandboxList(username, SANDBOXES_LIST_PAGE_SIZE, sandboxListFilterOptions);

    useEffect(() => {
        dispatch(
            setPageTitle({
                title: "Control Plane: Sandboxes",
                crumbs: [
                    {
                        title: "Control Plane",
                    },
                    {
                        title: "Sandboxes",
                    },
                ],
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setSandboxListFilterOptions({ filterModel: { ...filterModel } });
    }, []);

    return (
        <Grid container>
            <DataGridPremium
                filterMode="server"
                onFilterModelChange={onFilterChange}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
                getRowId={(row) => row.sandbox_id}
                columns={SANDBOXES_LIST_COLUMNS}
                rows={sandboxList?.items ?? []}
                slots={{ toolbar: GridToolbar }}
            />
        </Grid>
    );
};

export default React.memo(SandboxesList);
