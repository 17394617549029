// libs
import { styled } from "@mui/material";

const drawerWidth = "18rem";

// props
interface IMainProps {
    open: boolean;
    isUserAuthorized: boolean;
}

const Main = styled("main", {
    shouldForwardProp: (prop) => prop !== "open",
})<IMainProps>(({ theme, open, isUserAuthorized }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    position: "relative",
    ...(isUserAuthorized &&
        open && {
            marginLeft: drawerWidth,
            width: `calc(100vw - ${drawerWidth})`,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
}));

export default Main;
