// libs
import { ulid } from "ulid";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";

// store
import { startLoading, stopLoading } from "../../../../../../store/loader-reducer";
import { useInputSelectorChangeMode } from "../InputChangeSelectorProvider";

// components
import { Stack, Switch, Typography } from "@mui/material";
import { InputChangeCell } from "./components/InputChangeCell";
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowClassNameParams, GridValidRowModel } from "@mui/x-data-grid-pro";

// utils
import { AllocationType, InputDictionaryDiff, InputType, UserSandboxAccess } from "../../../../../../utils/appsync/schema/API";

// services
import { getInputDictionaryDiff } from "../../../../../../services/InputService";

// hooks
import { useAlertMessageHandler } from "../../../../../../hooks/useErrorMessageHandler";

export interface IInputChangesTableProps {
    input_id: string;
    inputType: InputType;
    allocationType: AllocationType;
    onFetchDiffDataCallback: (diffData: InputDictionaryDiff) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const InputChangesTable: React.FC<IInputChangesTableProps> = ({ input_id, onFetchDiffDataCallback }) => {
    const selectedSandbox: UserSandboxAccess = useSelector((state: any) => {
        return state.userInfo.selectedSandbox;
    });
    const [columnSchema, setColumnSchema] = useState<Array<any>>([]);
    const [diffData, setDiffData] = useState<Array<any>>([]);
    const { changeMode, toggleMode } = useInputSelectorChangeMode();
    const dispatch = useDispatch();

    const { showErrorAlertMessage } = useAlertMessageHandler();

    const fetchDiffData = async (): Promise<void> => {
        dispatch(startLoading());

        try {
            const jsonDiffData = await getInputDictionaryDiff(selectedSandbox.sandbox_id, input_id);
            setColumnSchema(jsonDiffData.input_schema!!);
            setDiffData(JSON.parse(jsonDiffData.diff));
            onFetchDiffDataCallback(jsonDiffData);
        } catch ({ errors }) {
            showErrorAlertMessage(errors);
        }

        dispatch(stopLoading());
    };

    useEffect(() => {
        if (selectedSandbox?.sandbox_id && input_id) {
            fetchDiffData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSandbox, input_id]);

    const getRowId = (row: GridValidRowModel): string => {
        return ulid();
    };

    const getDataGridColumnSchema = useMemo(() => {
        return columnSchema
            .filter((v: any) => v.type !== "IGNORE")
            .map((v: any, indx: number): GridColDef => {
                return {
                    field: v.name,
                    headerName: v.label,
                    renderCell: (params: GridRenderCellParams<any, any>) => {
                        if (params.value?.oldValue !== params.value?.newValue) {
                            return (
                                <InputChangeCell
                                    schema={columnSchema[indx]}
                                    oldValue={params.value?.oldValue}
                                    newValue={params.value?.newValue}
                                    addition={params.value?.addition}
                                    removal={params.value?.removal}
                                    common={params.value?.common}
                                />
                            );
                        } else {
                            return params.value?.oldValue;
                        }
                    },
                };
            });
    }, [columnSchema]);

    const getRowClassName = (params: GridRowClassNameParams): string => {
        return params?.row?.request_type?.newValue === "Delete Mapping" ? "disabledInputChangeRow" : "";
    };

    return (
        <Stack>
            <Stack direction="row" alignItems="center" sx={{ paddingRight: 2 }}>
                <Switch checked={!changeMode} onChange={toggleMode} size="small" />
                <Typography variant="body2">Disable formatting</Typography>
            </Stack>
            <DataGridPro getRowId={getRowId} columns={getDataGridColumnSchema} rows={diffData} getRowClassName={getRowClassName} />
        </Stack>
    );
};

export default React.memo(InputChangesTable);
