// libs
import React from "react";

// components
import { Modal, Grid, Stack, Typography, CircularProgress } from "@mui/material";

// colors
import { Action } from "../../styles/colors";

const Loader: React.FC = () => {
    return (
        <Modal open={true} disableEscapeKeyDown={true} onClose={() => false}>
            <Grid container alignContent="center" justifyContent="center" sx={{ width: "100vw", height: "100vh" }}>
                <Grid item sx={{ backgroundColor: Action.white, p: 4, borderRadius: 2 }}>
                    <Stack alignItems="center" spacing={2}>
                        <CircularProgress />
                        <Typography>Loading...</Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default React.memo(Loader);
