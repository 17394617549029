// colors
import { Action } from "../colors";

export const MuiDialogComponent = {
    styleOverrides: {
        root: {
            ".MuiPaper-root": {
                maxWidth: "none",
                borderRadius: "10px",
            },

            ".MuiDialogTitle-root": {
                backgroundColor: Action.background[70],
            },
        },
    },
};
