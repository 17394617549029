// libs
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

// utils
import { formatFilterFromDataGrid } from "../utils";
import { getClient, graphQlQueries } from "../utils/appsync";
import { SandboxListPage, Sandbox } from "../utils/appsync/schema/API";

// constants
import { SANDBOXES_LIST_FILTER_FIELDS } from "../screens/control-plane/sandboxes/constants";

export const useGetSandboxList = (
    userAlias: string,
    limit: number = 20,
    sandboxListFilterOptions?: object,
    nextToken?: string,
): UseQueryResult<Partial<SandboxListPage>, Error> => {
    const filters = formatFilterFromDataGrid(sandboxListFilterOptions, SANDBOXES_LIST_FILTER_FIELDS);

    return useQuery<Partial<SandboxListPage>, Error>({
        queryKey: ["sandboxList", userAlias, limit, filters, nextToken],
        queryFn: async () => {
            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlQueries.ListSandboxes,
                variables: {
                    userAlias: userAlias,
                    is_active: true,
                    limit,
                    nextToken,
                    filters,
                },
            });

            return { items: data.ListSandboxes.items!!.filter((val: Sandbox | null) => val != null).map((val: Sandbox | null) => val!!) };
        },
        enabled: !!userAlias,
        // setting staleTime and gcTime to 5 mins.
        staleTime: 5 * 60 * 1000,
        gcTime: 5 * 60 * 1000,
    });
};
