// libs
import { useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";

// store
import { stopLoading } from "../../store/loader-reducer";
import { setPageTitle } from "../../store/page-title-reducer";

// components
import { ExpandLess, ExpandMore, FileDownload } from "@mui/icons-material";
import { Container, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";

// utils
import { costCenterRenderer } from "../../utils/renderers/cost-center-renderer";
import { businessInclusionsRenderer } from "../../utils/renderers/business-inclusions-renderer";

// servies
import { INPUT_TYPES } from "../../utils/constants";

// styles
// eslint-disable-next-line import/no-unassigned-import
import styles from "./AllocationConfig.module.scss";

// defs
import { AllocationConfigTable } from "./components/Table";
import { createSearchParams } from "react-router-dom";

costCenterRenderer.register();
businessInclusionsRenderer.register();

const AllocationConfig: React.FC = () => {
    const { inputType } = useParams();
    const [lastModifiedDate, setLastModifiedDate] = useState("");

    const { loading, allocationType, selectedSandbox } = useSelector(({ loader, userInfo }) => ({
        loading: loader.loadingCount > 0,
        allocationType: userInfo.allocationType.key,
        selectedSandbox: userInfo.selectedSandbox,
    }));

    const [selectedCellData, setSelectedCellData] = useState("");

    const [multilineTextField, setMultilineTextField] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const hotRef = useRef(null);

    useEffect(() => {
        // Add extra stop loading to hide loader for the first time when page loads
        dispatch(stopLoading());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const inputTypeObject = INPUT_TYPES.filter((v) => v.key === inputType)[0] ?? INPUT_TYPES[0];
        if (!inputType) {
            navigate({
                pathname: "/dictionary/" + inputTypeObject.label,
                search: createSearchParams({
                    sandboxId: selectedSandbox.sandbox_id,
                    allocationType: allocationType.key,
                }).toString(),
            });
        } else {
            dispatch(
                setPageTitle({
                    title: "Dictionary View: " + inputTypeObject.label,
                    crumbs: [
                        {
                            title: "Dictionary",
                        },
                        {
                            title: inputTypeObject.label,
                        },
                    ],
                }),
            );
        }
        setSelectedCellData("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputType]);

    const onUpdateSelectedContent = (selectedContent): void => {
        setSelectedCellData(selectedContent);
    };

    const onUpdateLastModifiedDate = (updatedLastModifiedDate: string): void => {
        setLastModifiedDate(updatedLastModifiedDate);
    };

    const toggleMultilineTextField = (): void => setMultilineTextField(!multilineTextField);

    const onDownloadDictionary = (): void => {
        const hotInstance = hotRef?.current?.hotInstance;
        const inputTypeObject = INPUT_TYPES.filter((v) => v.key === inputType)[0] ?? INPUT_TYPES[0];

        if (hotInstance) {
            const exportPlugin = hotInstance.getPlugin("exportFile");
            exportPlugin.downloadFile("csv", {
                columnDelimiter: ",",
                columnHeaders: true,
                exportHiddenColumns: false,
                exportHiddenRows: false,
                fileExtension: "csv",
                filename: `Dictionary_${inputTypeObject.label}_[YYYY]-[MM]-[DD]`,
                mimeType: "text/csv",
                rowDelimiter: "\r\n",
                rowHeaders: false,
            });
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} className={styles.table__action_bar}>
                <Stack direction="row" alignItems="center">
                    <TextField
                        variant="outlined"
                        label="Selected Content"
                        value={selectedCellData}
                        autoComplete="off"
                        fullWidth={true}
                        size="small"
                        multiline={multilineTextField}
                        helperText="This field is only to view the complete record. Editing will have no impact"
                    />
                    <IconButton onClick={toggleMultilineTextField}>{multilineTextField ? <ExpandLess /> : <ExpandMore />}</IconButton>
                    <Container className={styles.table_refresh_date__div}>
                        <Typography className={styles.table_refresh_date}>
                            <span className={styles.table_refresh_date_span}>Last modified:</span> {lastModifiedDate ?? ""}
                        </Typography>
                    </Container>
                    <IconButton onClick={onDownloadDictionary}>
                        <FileDownload />
                    </IconButton>
                </Stack>
            </Grid>
            <Grid item xs={12} className={styles.table__container} id="containerID">
                {!loading && (
                    <AllocationConfigTable
                        ref={hotRef}
                        inputType={inputType}
                        allocationType={allocationType}
                        onUpdateSelectedContent={onUpdateSelectedContent}
                        onUpdateLastModifiedDate={onUpdateLastModifiedDate}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default React.memo(AllocationConfig);
