// colors
import { Action } from "../colors";

export const MuiGridComponent = {
    styleOverrides: {
        root: {
            ".MuiDataGrid-root": {
                border: "none",
            },

            ".MuiDataGrid-columnHeaders": {
                backgroundColor: Action.background[50],
            },

            ".MuiDataGrid-rowCount": {
                display: "none",
            },

            "& .disabledInputChangeRow": {
                opacity: 0.5,
                pointerEvents: "none",
            },
        },
    },
};
