// libs
import React from "react";

// components
import { IconButton, Popover, List, ListItemButton } from "@mui/material";

// icons
import { MoreVert } from "@mui/icons-material";

// props
import { ISimAction, ISimActionsProps, SIM_ACTIONS } from "./SimActions.props";

const Actions: React.FC<ISimActionsProps> = ({ sim, onSimAction }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const onSimActionIconClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const onSimActionClose = (): void => {
        setAnchorEl(null);
    };

    const prepareActionsList = (): React.ReactElement => {
        return (
            <List>
                {SIM_ACTIONS.map(({ title, actionType, enableStatusList }: ISimAction) => {
                    if (!enableStatusList.includes(sim.status)) return <React.Fragment></React.Fragment>;

                    return (
                        <ListItemButton key={actionType} onClick={() => onSimAction(actionType, sim)}>
                            {title}
                        </ListItemButton>
                    );
                })}
            </List>
        );
    };

    return (
        <React.Fragment>
            <IconButton onClick={onSimActionIconClick}>
                <MoreVert fontSize="small" />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onSimActionClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}>
                {prepareActionsList()}
            </Popover>
        </React.Fragment>
    );
};

export default React.memo(Actions);
