// types
import { IAllocationType } from "../types/utilities";
import { ALLOCATION_TYPES } from "../utils/constants";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserSandboxAccess } from "../utils/appsync/schema/API";

export type UserInfo = {
    email?: string;
    username?: string;
    isUserAuthorized?: boolean;
    selectedSandbox?: UserSandboxAccess;
    sandboxes?: Array<UserSandboxAccess>;
    allocationType?: IAllocationType;
};

export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: {
        username: "",
        email: "",
        isUserAuthorized: false,
        selectedSandbox: undefined,
        sandboxes: [],
        allocationType: ALLOCATION_TYPES[0],
    },
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            return { ...state, username: action.payload };
        },
        setEmail: (state, action: PayloadAction<string>) => {
            return { ...state, email: action.payload };
        },
        setIsUserAuthorized: (state, action: PayloadAction<boolean>) => {
            return { ...state, isUserAuthorized: action.payload };
        },
        setSelectedSandbox: (state, action: PayloadAction<UserSandboxAccess>) => {
            return { ...state, selectedSandbox: action.payload };
        },
        setSandboxes: (state, action: PayloadAction<Array<UserSandboxAccess>>) => {
            return { ...state, sandboxes: action.payload };
        },
        setUserInfo: (state, action: PayloadAction<UserInfo>) => {
            return { ...state, ...action.payload };
        },
        setAllocationType(state, action: PayloadAction<number>) {
            return { ...state, allocationType: ALLOCATION_TYPES[action.payload] };
        },
    },
});

export const { setUsername, setEmail, setIsUserAuthorized, setSelectedSandbox, setSandboxes, setUserInfo, setAllocationType } =
    userInfoSlice.actions;

export default userInfoSlice.reducer;
