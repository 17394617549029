// libs
import React, { useMemo } from "react";

// components
import { Box, Typography } from "@mui/material";
import { CustomTooltip } from "../../../../../../../../components/CustomTooltip";

// hooks
import { useInputSelectorChangeMode } from "../../../InputChangeSelectorProvider";

// helpers
import { AllocationConfigSchemaColumn } from "../../../../../../../../utils/appsync/schema/API";

export interface IInputChangeCellProps {
    // contentItem: string,
    schema: AllocationConfigSchemaColumn;
    // dictionaryData: string,
    oldValue: string;
    newValue: string;
    addition?: Array<string>;
    removal?: Array<string>;
    common?: Array<string>;
}

const InputChangeCell: React.FC<IInputChangeCellProps> = (props: IInputChangeCellProps) => {
    const { changeMode } = useInputSelectorChangeMode();

    const isChanged = useMemo(() => props.newValue !== props.oldValue, [props.newValue, props.oldValue]);

    if (isChanged && changeMode && (!!props.addition?.length || !!props.removal?.length || !!props.common?.length)) {
        return (
            <Box>
                <CustomTooltip title={`Dictionary Value: ${props.oldValue}`} placement="top-start">
                    <Box>
                        <CustomTooltip title={`New Value: ${props.newValue}`} placement="bottom-start">
                            <Typography>
                                {!!props.removal?.length && (
                                    <Typography sx={{ textDecoration: "line-through" }}>
                                        {props.removal.reduce((ac, v) => ac + ";" + v)}
                                    </Typography>
                                )}
                                {!!props.addition?.length && (
                                    <Typography fontWeight="bold">{props.addition.reduce((ac, v) => ac + ";" + v)}</Typography>
                                )}
                                {!!props.common?.length && <Typography>{props.common.reduce((ac, v) => ac + ";" + v)}</Typography>}
                            </Typography>
                        </CustomTooltip>
                    </Box>
                </CustomTooltip>
            </Box>
        );
    }

    const getWidth = (): object => {
        if (props.newValue) {
            if (props.schema.name && props.schema.name.indexOf("description") > -1) {
                return {
                    width: "20rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                };
            }
        }
        return {};
    };

    return (
        <CustomTooltip title={props.newValue} placement="bottom-start">
            <Box
                sx={{ color: changeMode ? "grey" : "primary", whiteSpace: "nowrap", overflow: "hidden", ...getWidth() }}
                textOverflow="ellipsis">
                {props.newValue}
            </Box>
        </CustomTooltip>
    );
};

export default React.memo(InputChangeCell);
