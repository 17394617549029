// libs
import React, { useState, createContext, ReactNode, useContext } from "react";

interface InputChangeSelectorState {
    changeMode: boolean;
    switchToChangeMode?: () => void;
    switchToFullMode?: () => void;
    toggleMode?: () => void;
}

export const InputChangeSelectorModeContext = createContext<InputChangeSelectorState>({
    changeMode: true,
});

export const InputChangeSelectorModeProvider = (props: { children: ReactNode }): React.ReactElement => {
    const [changeMode, setChangeMode] = useState<boolean>(true);
    const switchToChangeMode = (): void => {
        setChangeMode(true);
    };
    const switchToFullMode = (): void => {
        setChangeMode(false);
    };
    const toggleMode = (): void => {
        setChangeMode(!changeMode);
    };

    const stateProvider = {
        changeMode,
        switchToChangeMode,
        switchToFullMode,
        toggleMode,
    };

    return <InputChangeSelectorModeContext.Provider value={stateProvider}>{props.children}</InputChangeSelectorModeContext.Provider>;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useInputSelectorChangeMode = () => useContext(InputChangeSelectorModeContext);
