// libs
import React, { useMemo } from "react";

// components
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// types
import { SxProps } from "@mui/material";

// styles
import { pageTitleBarSelect } from "./PageTitleBarSelect.style";

interface IPageTitleBarSelectProps {
    label: string;
    value: any;
    options?: Array<IPageTitleBarSelectProps>;
    onChange?: (e: any) => void;
    multiple?: boolean;
    readonly?: boolean;
    sx?: SxProps;
}

const PageTitleBarSelect: React.FC<IPageTitleBarSelectProps> = (props: IPageTitleBarSelectProps) => {
    const defaultValue = useMemo((): Array<string> | string => {
        return props.multiple ? [] : "";
    }, [props]);

    const uniqueId = useMemo(() => {
        return `${props.label}-${Math.random().toString(36).slice(2, 7)}`;
    }, [props.label]);

    const changeHandler = (evt: any): void => {
        props.onChange?.(evt.target.value);
    };

    return (
        <FormControl
            size="small"
            disabled={!!props.readonly}
            sx={{
                ...pageTitleBarSelect,
                ...props?.sx,
            }}>
            <InputLabel id={uniqueId}>{props.label}</InputLabel>
            <Select
                labelId={uniqueId}
                label={props.label}
                value={props.value ?? defaultValue}
                onChange={changeHandler}
                color="info"
                multiple={props.multiple}>
                {props.options?.map((opt) => (
                    <MenuItem value={opt.value} key={`${props.label}-${opt.label}-${opt.value}`}>
                        {opt.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default React.memo(PageTitleBarSelect);
