export const getOperatorFromDataGridToApi = (operator: string): string => {
    switch (operator) {
        case "equals":
            return "eq";
        case "startsWith":
            return "beginsWith";
        default:
            return operator;
    }
};

/**
 * truncateString function truncate the string and append elipsis.
 * @param str : input string
 * @param n : length, if string is greater then passed length then it will truncate
 */
export function truncateString(str: string, n: number): string {
    if (str == null) {
        return "";
    }
    if (str.length > n) {
        return str.substring(0, n) + "...";
    } else {
        return str;
    }
}

const validFilterOperations = ["equals", "startsWith"];

/**
 * formatFilterFromDataGrid connects the filter from the datagrid toolbar to the filters
 * used by the backend
 * @param Object : filterOptions object passed from datagrid toolbar with the status of
 * the datagrid toolbar filter
 * @param filterFields : list of strings that represent the fields available for filtering
 *  at the data that is displayed in the datagrid
 */
export const formatFilterFromDataGrid = (filterOptions: any, filterFields: Array<string>): object => {
    let filters: any | undefined;
    if (filterOptions.filterModel?.items?.length) {
        filters = {};
        filterOptions.filterModel.items.forEach((filterItem: any) => {
            if (filterFields.includes(filterItem.field) && validFilterOperations.includes(filterItem.operator)) {
                filters[filterItem.field] = {
                    [getOperatorFromDataGridToApi(filterItem.operator)]: filterItem.value,
                };
            }
        });
    }
    return filters;
};
