// libs
import { useCallback } from "react";
import { useDispatch } from "react-redux";

// stores
import { showAlertMessage, hideAlertMessage } from "../../store/page-alert-reducer";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useBannerHandler() {
    const dispatch = useDispatch();

    const showBanner = useCallback((bannerMessage): void => {
        dispatch(showAlertMessage({ bannerMessage }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hideBanner = useCallback((): void => {
        dispatch(hideAlertMessage());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { showBanner, hideBanner };
}

export default useBannerHandler;
