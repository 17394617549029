// libs
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import { Feedback } from "../../Feedback";
import { AppBar } from "../components/AppBar";
import { Menu as MenuClosed, MenuOpen } from "@mui/icons-material";
import { IconButton, Toolbar, Box, Link, Avatar } from "@mui/material";

// stores
import { toggleMenu } from "../../../store/side-menu-reducer";

// types
import { IFeedback } from "../../Feedback/types/Feedback";

// services
import { useCreateSimTicket } from "../../../services/SimTicketService";

// constants
import { T_CORP_TICKET_URL } from "../../../constants";

// hooks
import { useAlertMessageHandler } from "../../../hooks/useErrorMessageHandler";

// props
interface IHeaderProps {
    isUserAuthorized: boolean;
    actionButtons?: Array<React.ReactElement>;
}

const Header: React.FC<IHeaderProps> = ({ isUserAuthorized, actionButtons }: IHeaderProps) => {
    const open = useSelector(({ sideMenu }: any) => sideMenu.open);

    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
    const [submittedFeedbackUrl, setSubmittedFeedbackUrl] = useState("");

    const dispatch = useDispatch();

    const createSimTicket = useCreateSimTicket();

    const { showErrorAlertMessage } = useAlertMessageHandler();

    const { username } = useSelector((state: any) => ({
        username: state.userInfo?.username,
    }));

    const onFeedbackSubmit = async (feedback: IFeedback): Promise<void> => {
        try {
            const { ticketID } = await createSimTicket.mutateAsync({
                userName: feedback.userName,
                title: feedback.title,
                description: feedback.description,
            });
            setIsFeedbackSubmitted(true);
            setSubmittedFeedbackUrl(`${T_CORP_TICKET_URL}/${ticketID}`);
        } catch ({ errors }) {
            showErrorAlertMessage(errors);
        }
    };

    const onFeedbackClose = (): void => {
        // in case of closing of feedback dialog after feedback submit, reset the feedback submitted form state.
        if (isFeedbackSubmitted) {
            setIsFeedbackSubmitted(false);
            setSubmittedFeedbackUrl("");
        }
    };

    return (
        <AppBar
            position="fixed"
            color="default"
            variant="outlined"
            elevation={0}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}>
            <Toolbar>
                {isUserAuthorized && <IconButton onClick={() => dispatch(toggleMenu())}>{open ? <MenuOpen /> : <MenuClosed />}</IconButton>}
                <Link href="/" sx={{ flexGrow: 1 }}>
                    <img src="../../../A3S_logo.png" height="36" />
                </Link>
                <Box sx={{ px: 2 }}>
                    {!!actionButtons?.length && (
                        <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>{actionButtons}</div>
                    )}
                </Box>
                <Feedback
                    title="A3S Feedback"
                    userName={String(username)}
                    isFeedbackSubmitted={isFeedbackSubmitted}
                    submittedUrl={submittedFeedbackUrl}
                    onFeedbackClose={onFeedbackClose}
                    onFeedbackSubmit={onFeedbackSubmit}
                />
                {!!username && <Avatar alt={username} src={`https://badgephotos.corp.amazon.com/?uid=${username}`} />}
            </Toolbar>
        </AppBar>
    );
};

export default React.memo(Header);
