// types
import { SxProps } from "@mui/material";

// colors
import { Primary } from "../../../styles/colors";

export const newSimulationTitleContainer: SxProps = {
    padding: "12px",
    color: Primary.white,
    borderRadius: "4px",
    backgroundColor: Primary[10],
};

export const newSimulationSaveAction: SxProps = {
    margin: "0 8px 0 0",
    color: Primary.white,

    "&.Mui-disabled": {
        opacity: 0.5,
        color: Primary.white,
    },
};

export const newSimulationTextField: SxProps = {};

export const newSimulationSelect: SxProps = {
    backgroundColor: Primary.white,
    color: Primary.white,
};
