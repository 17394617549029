import { createSlice } from "@reduxjs/toolkit";

export const sideMenuSlice = createSlice({
    name: "sideMenu",
    initialState: {
        open: true,
    },
    reducers: {
        openMenu: (state) => {
            state.open = true;
        },
        closeMenu: (state) => {
            state.open = false;
        },
        toggleMenu: (state) => {
            state.open = !state.open;
        },
    },
});

export const { openMenu, closeMenu, toggleMenu } = sideMenuSlice.actions;

export default sideMenuSlice.reducer;
