// types
import { Sim, SimStatus } from "../../../../../utils/appsync/schema/API";

export enum SimActionType {
    START = "START",
    CANCEL = "CANCEL",
    DELETE = "DELETE",
    VIEW_REPORT = "VIEW_REPORT",
    VIEW_CONFIGURATION = "VIEW_CONFIGURATION",
}

export interface ISimAction {
    title: string;
    actionType: SimActionType;
    enableStatusList: Array<SimStatus>;
}

export interface ISimActionsProps {
    sim: Sim;
    onSimAction: (simActionType: SimActionType, sim: Sim) => void;
}

// action list
export const SIM_ACTIONS: Array<ISimAction> = [
    {
        title: "View",
        actionType: SimActionType.VIEW_CONFIGURATION,
        enableStatusList: [SimStatus.CREATED, SimStatus.STARTED, SimStatus.FAILED, SimStatus.CANCELED],
    },
    {
        title: "View",
        actionType: SimActionType.VIEW_REPORT,
        enableStatusList: [SimStatus.COMPLETED],
    },
    {
        title: "Start",
        actionType: SimActionType.START,
        enableStatusList: [SimStatus.CREATED],
    },
    {
        title: "Cancel",
        actionType: SimActionType.CANCEL,
        enableStatusList: [SimStatus.STARTED],
    },
    {
        title: "Delete",
        actionType: SimActionType.DELETE,
        enableStatusList: [SimStatus.CANCELED, SimStatus.FAILED, SimStatus.COMPLETED, SimStatus.CREATED],
    },
];
