// libs
import React from "react";

// types
import { SideMenuEntry } from "../../../types/sidemenu";

// constants
import { INPUT_TYPES } from "../../../utils/constants";
import { IAllocationType } from "../../../types/utilities";

// icons
import { AutoAwesomeOutlined, MenuBook } from "@mui/icons-material";

export const sideMenuItems: Array<SideMenuEntry> = [
    {
        label: "Control Plane",
        heading: true,
        hide: true,
    },
    {
        label: "Sandboxes",
        path: "/control-plane/sandboxes",
        hide: true,
    },
    {
        label: "Simulations",
        icon: <AutoAwesomeOutlined />,
        heading: true,
    },
    {
        label: "New Simulation",
        path: "/simulation",
    },
    {
        label: "Simulations History",
        path: "/simulations",
        divider: true,
    },
    {
        label: "Changes",
        heading: true,
        hide: true,
    },
    {
        label: "MCM Drafts",
        path: "/home",
        disabled: true,
        hide: true,
    },
    {
        label: "System Dictionary",
        heading: true,
        path: "",
        icon: <MenuBook />,
    },
    ...INPUT_TYPES.map(
        (inputType: IAllocationType): SideMenuEntry => ({
            label: inputType.label,
            path: "/dictionary/" + inputType.key,
        }),
    ),
];
