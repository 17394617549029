// libs
import { GraphQLResult } from "@aws-amplify/api-graphql";

// utils
import { getClient, graphQlQueries } from "../utils/appsync";
import { InputListPage, AllocationType, InputType } from "../utils/appsync/schema/API";

// schema
import { InputDictionaryDiff } from "../utils/appsync/schema/API";

export const getActiveInputList = async (
    sandbox_id: string,
    allocation_type: AllocationType,
    input_type: InputType,
    limit = 10,
    offset = 0,
    filters = [""],
): Promise<InputListPage> => {
    const { data }: GraphQLResult<any> = await getClient().graphql({
        query: graphQlQueries.ListInput,
        variables: {
            sandbox_id,
            allocation_type,
            input_type,
            limit,
            offset,
            filters,
        },
    });
    return data.ListInput;
};

export const getInputDictionaryDiff = async (sandbox_id: string, input_id: string): Promise<InputDictionaryDiff> => {
    const { data }: GraphQLResult<any> = await getClient().graphql({
        query: graphQlQueries.ListInputDictionaryDiff,
        variables: {
            sandbox_id,
            input_id,
        },
    });
    return data.ListInputDictionaryDiff;
};
