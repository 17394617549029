// libs
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

// utils
import { ConfigType } from "../utils/appsync/schema/API";
import { getClient, graphQlQueries } from "../utils/appsync";

export const useGetFeatureFlags = (userAlias: string): UseQueryResult<object, Error> => {
    return useQuery<object, Error>({
        queryKey: ["featureFlags", userAlias],
        queryFn: async () => {
            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlQueries.GetConfigurations,
                variables: {
                    userAlias,
                    configType: ConfigType.FEATURE_FLAG,
                },
            });

            return data.GetConfigurations?.content?.length ? JSON.parse(data.GetConfigurations.content) : {};
        },
        enabled: !!userAlias,
    });
};
