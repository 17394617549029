// libs
import { Tuple, configureStore } from "@reduxjs/toolkit";
import { Middlewares } from "@reduxjs/toolkit/dist/configureStore";

// reducers
import loaderReducer from "./loader-reducer";
import sideMenuReducer from "./side-menu-reducer";
import userInfoReducer from "./user-info-reducer";
import pageTitleReducer from "./page-title-reducer";
import pageAlertReducer from "./page-alert-reducer";

export const store = configureStore<any, any, Tuple<Middlewares<any>>>({
    devTools: true,
    reducer: {
        loader: loaderReducer,
        userInfo: userInfoReducer,
        sideMenu: sideMenuReducer,
        pageTitle: pageTitleReducer,
        pageAlert: pageAlertReducer,
    },
});
