import Core from "handsontable/core";
import Handsontable from "handsontable";
import { CellProperties } from "handsontable/settings";
//import { textRenderer } from 'handsontable/renderers/textRenderer';

const costCenterName = "cost-center";

const renderCostCenterElement = (parentElement: HTMLElement, costCenters: string): void => {
    // const splitList = costCenters?.split(";")?.filter(v => !!v).map(
    //     (v: string) => `
    //         <span class="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-filledDefault">
    //             <span class="MuiChip-label MuiChip-labelMedium">
    //                 ${v}
    //             </span>
    //         </span>
    //     `
    // );
    const htmlBody = costCenters
        ? `
        <div class="MuiContainer">
            <span class="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-filledDefault">
                <span class="MuiChip-label MuiChip-labelMedium">
                    ${costCenters}
                </span>
            </span>
        </div>
    `
        : "";
    parentElement.setAttribute("class", "MuiGrid-root MuiGrid-item css-11lq3yg-MuiGrid-root");
    parentElement.innerHTML = htmlBody;
};

export const costCenterRenderer = {
    name: costCenterName,
    register() {
        const costCenterRenderer = (
            instance: Core,
            td: HTMLTableCellElement,
            row: number,
            column: number,
            prop: string | number,
            value: any,
            cellProperties: CellProperties,
        ): void => {
            if (value) {
                // Optionally include `BaseRenderer` which is responsible for
                // adding/removing CSS classes to/from the table cells.
                //textRenderer.apply(this, arguments);

                const baseElement = document.createElement("div");
                // Add the value to act as base for width calculation
                td.innerHTML = "";
                td.appendChild(baseElement);
                renderCostCenterElement(baseElement, value + "");
            }
        };

        Handsontable.renderers.registerRenderer(costCenterName, costCenterRenderer);
        return costCenterRenderer;
    },
};
