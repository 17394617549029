// colors
import { Primary } from "../../../../styles/colors";

export const pageTitleBarButton = {
    color: Primary.white,
    backgroundColor: Primary.main,

    "&.Mui-disabled": {
        opacity: 0.5,
        color: Primary.white,
    },
};
