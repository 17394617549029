// colors
import { Action, Primary } from "../../../../styles/colors";

export const pageTitleBarSelect = {
    width: "12em",
    borderRadius: "4px",

    // selected value color
    ".MuiSelect-select": {
        color: Primary.white,

        "&.Mui-disabled": {
            color: Primary.white,
            "-webkit-text-fill-color": Primary.white,
        },
    },

    // legend color
    ".MuiInputLabel-root": {
        color: Primary.white,

        "&.Mui-disabled": {
            color: Action.main,
        },
    },

    // placeholder color
    ".MuiInputBase-root": {
        color: Primary.white,
        backgroundColor: Primary[10],

        // dropdown border styling
        ".MuiOutlinedInput-notchedOutline": {
            borderColor: `${Primary.white} !important`,
        },

        "&.Mui-disabled": {
            // dropdown border styling
            ".MuiOutlinedInput-notchedOutline": {
                border: `1px dotted ${Action.main} !important`,
            },
        },
    },

    // icon color
    ".MuiSvgIcon-root": {
        color: `${Primary.white} !important`,

        "&.Mui-disabled": {
            color: `${Action.main} !important`,
        },
    },
};
