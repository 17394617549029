// types
import { SxProps } from "@mui/material";

export const feedbackContainer: SxProps = {
    ".MuiPaper-root": {
        height: "480px",
        width: "998px",
    },
};

export const fpaOneIcon: SxProps = {
    top: "16px",
    right: "24px",
    position: "absolute",
};
