// libs
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

// components
import {
    Stack,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Drawer,
    IconButton,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Divider,
    Typography,
    Grid,
    Link,
} from "@mui/material";
import { Noop } from "../../Noop";
import { DrawerHeader } from "../components/DrawerHeader";
import { MenuOpen, OpenInNewRounded } from "@mui/icons-material";

// store
import { toggleMenu } from "../../../store/side-menu-reducer";
import { setAllocationType, setSelectedSandbox, setUserInfo } from "../../../store/user-info-reducer";

// constants
import { sideMenuItems } from "./SideMenuItems";
import { MCM_INPUT_TOOL_LINK } from "../../../constants";
import { ALLOCATION_TYPES } from "../../../utils/constants";

// styles
import {
    sideMenuItemIcon,
    sideMenuSandboxSelection,
    sideMenuAllocationTypeSelection,
    sideMenuItemText,
    sideMenuItemButton,
    sideMenu,
    sideMenuItemDivider,
    // sideMenuSandboxControls,
    allocationMCMTool,
    allocationMCMToolTitle,
    allocationMCMToolDiscription,
    sideMenuDrawer,
    sideMenuItem,
} from "./SideMenu.style";

// types
import {
    ALLOCATION_TYPE_PARAM,
    SANDBOX_ID_PARAM,
    SIMULATIONS_ROUTES,
    SIMULATION_ROUTES,
    getParamFromRouteQuery,
    isCurrentRouteMatching,
} from "../../../router";
import { IAllocationType } from "../../../types/utilities";
import { UserSandboxAccess } from "../../../utils/appsync/schema/API";

const SideMenu: React.FC = () => {
    const open = useSelector(({ sideMenu }: any) => sideMenu.open);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();

    const { sandboxes, allocationType, selectedSandbox } = useSelector((state: any) => ({
        sandboxes: state.userInfo?.sandboxes,
        allocationType: state.userInfo?.allocationType,
        selectedSandbox: state.userInfo?.selectedSandbox,
    }));

    useEffect(() => {
        if (location.search) {
            const sandboxId = getParamFromRouteQuery(location.search, SANDBOX_ID_PARAM);
            const allocationTypeInUrl = getParamFromRouteQuery(location.search, ALLOCATION_TYPE_PARAM);
            const userInfoToSet = {};
            if (sandboxId) {
                const selectedSandbox = sandboxes.filter((sandbox: UserSandboxAccess) => sandbox.sandbox_id === sandboxId)[0];
                userInfoToSet[SANDBOX_ID_PARAM] = selectedSandbox;
            }
            if (allocationTypeInUrl) {
                userInfoToSet[ALLOCATION_TYPE_PARAM] = ALLOCATION_TYPES.filter((v: IAllocationType) => v.key === allocationTypeInUrl)[0];
            }
            dispatch(setUserInfo(userInfoToSet));
        }
    }, [location]);

    const navigateUser = (path?: string) => {
        return () =>
            path &&
            navigate({
                pathname: path,
                search: createSearchParams({
                    sandboxId: selectedSandbox.sandbox_id,
                    allocationType: allocationType.key,
                }).toString(),
            });
    };

    const updateRoute = (selectedSandboxId?: string, selectedAllocationType?: string): void => {
        // if current route is simulation routes then redirect on Simulation History routes
        // in case of change in allcation type or sandbox.
        if (isCurrentRouteMatching(location.pathname, SIMULATION_ROUTES)) {
            navigate({
                pathname: SIMULATIONS_ROUTES[0].path,
                search: createSearchParams({
                    sandboxId: selectedSandboxId ?? selectedSandbox.sandbox_id,
                    allocationType: selectedAllocationType ?? allocationType.key,
                }).toString(),
            });
        } else if (!location.search || location.search.indexOf(selectedSandboxId) < 0) {
            // If the sandbox id is not present in the query parameter in the url or is different from the selected value:
            navigate({
                pathname: location.pathname,
                search: createSearchParams({
                    sandboxId: selectedSandboxId ?? selectedSandbox.sandbox_id,
                    allocationType: selectedAllocationType ?? allocationType.key,
                }).toString(),
            });
        }
    };

    const renderAllocationTypeSelection = (): React.ReactElement => {
        const onSelectAllocationType = (event: any): void => {
            updateRoute(selectedSandbox.sandbox_id, event.target.value);
            dispatch(setAllocationType(event.target.value));
        };

        return (
            <FormControl size="small" sx={sideMenuAllocationTypeSelection}>
                <InputLabel id="allocation-type-input-label">Allocation Type</InputLabel>
                <Select
                    labelId="allocation-type-input-label"
                    label="Allocation type"
                    size="small"
                    value={allocationType.id}
                    onChange={onSelectAllocationType}
                    fullWidth>
                    {ALLOCATION_TYPES.map((typeItem: any) => (
                        <MenuItem value={typeItem.id} key={typeItem.key}>
                            {typeItem.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const renderSandboxSelection = (): React.ReactElement => {
        const onSelectSandbox = (selectedSandboxId: string): void => {
            updateRoute(selectedSandboxId);
            dispatch(setSelectedSandbox(sandboxes.filter((sandbox) => sandbox.sandbox_id === selectedSandboxId)[0]));
        };

        return (
            <FormControl size="small" sx={sideMenuSandboxSelection}>
                <InputLabel id="sandbox-input-label">Sandbox</InputLabel>
                <Select
                    labelId="sandbox-input-label"
                    label="Sandbox"
                    size="small"
                    value={selectedSandbox?.sandbox_id ?? ""}
                    onChange={(event: any) => onSelectSandbox(event.target.value)}
                    fullWidth>
                    {sandboxes.map((sandbox: UserSandboxAccess) => (
                        <MenuItem value={sandbox.sandbox_id} key={sandbox.sandbox_id}>
                            {sandbox.sandbox_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const renderAllocationMCMTool = (): React.ReactElement => {
        return (
            <Grid container sx={allocationMCMTool} flexDirection="column">
                <Grid item>
                    <Link
                        target="_blank"
                        rel="noreferrer"
                        underline="none"
                        variant="subtitle2"
                        href={MCM_INPUT_TOOL_LINK}
                        sx={allocationMCMToolTitle}>
                        Allocation MCM Tool
                        <OpenInNewRounded sx={{ fontSize: 16 }}></OpenInNewRounded>
                    </Link>
                </Grid>
                <Grid item sx={allocationMCMToolDiscription}>
                    <Typography variant="body2">Users must first enter changes in MCM to run simulations.</Typography>
                </Grid>
            </Grid>
        );
    };

    const renderSideMenuList = (): React.ReactElement => {
        return (
            <List>
                {sideMenuItems.map(({ label, icon, path, heading, disabled, hide, divider }, index) => {
                    if (hide) return <Noop key={index + label} />;

                    return (
                        <>
                            <ListItem key={index + label} disablePadding sx={sideMenuItem(heading)}>
                                <ListItemButton
                                    disabled={!!disabled}
                                    disableRipple={!!heading}
                                    sx={sideMenuItemButton(open, heading)}
                                    onClick={navigateUser(path)}>
                                    {icon && <ListItemIcon sx={sideMenuItemIcon(heading)}>{icon}</ListItemIcon>}
                                    <ListItemText
                                        primary={label}
                                        primaryTypographyProps={
                                            heading
                                                ? {
                                                      fontSize: "14px",
                                                      fontWeight: "bold",
                                                  }
                                                : { fontSize: "14px" }
                                        }
                                        sx={sideMenuItemText(open, heading)}
                                    />
                                </ListItemButton>
                            </ListItem>
                            {divider && <Divider sx={sideMenuItemDivider} />}
                        </>
                    );
                })}
            </List>
        );
    };

    return (
        <Drawer open={open} anchor="left" variant="persistent" sx={sideMenuDrawer}>
            <DrawerHeader>
                <IconButton onClick={() => dispatch(toggleMenu())}>
                    <MenuOpen />
                </IconButton>
            </DrawerHeader>
            <Stack direction="column" justifyContent="space-between" sx={sideMenu}>
                <Stack direction="column">
                    {renderSandboxSelection()}
                    {renderAllocationTypeSelection()}
                    {renderAllocationMCMTool()}
                    {renderSideMenuList()}
                </Stack>
                {/* {open && (
                    <Stack direction="column">
                        <Divider sx={sideMenuItemDivider} />
                        <Typography sx={sideMenuSandboxControls}>Sandbox Controls</Typography>
                    </Stack>
                )} */}
            </Stack>
        </Drawer>
    );
};

export default React.memo(SideMenu);
