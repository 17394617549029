// libs
import React, { useState } from "react";

// components
import { Dialog, DialogTitle, IconButton, Typography } from "@mui/material";

// types
import { IFeedback } from "./types/Feedback";

// styles
import { Primary } from "../../styles/colors";
import { feedbackContainer, fpaOneIcon } from "./Feedback.style";

// icons
import { FPAOneIcon } from "./icons/FPAOne";
import { SentimentSatisfiedAlt } from "@mui/icons-material";

// components
import { FeedbackForm } from "./components/FeedbackForm";
import { FeedbackSubmitted } from "./components/FeedbackSubmitted";

export interface IFeedbackProps {
    title: string;
    userName: string;
    submittedUrl?: string;
    isFeedbackSubmitted?: boolean;
    onFeedbackClose: () => void;
    onFeedbackSubmit: (feedback: IFeedback) => void;
}

const Feedback: React.FC<IFeedbackProps> = ({ title, userName, isFeedbackSubmitted, submittedUrl, onFeedbackSubmit, onFeedbackClose }) => {
    const [openFeedback, toggleFeedback] = useState(false);

    const onClose = (): void => {
        toggleFeedback(false);
        onFeedbackClose();
    };

    return (
        <React.Fragment>
            <IconButton onClick={() => toggleFeedback(true)}>
                <SentimentSatisfiedAlt sx={{ m: "4px" }} />
            </IconButton>
            {openFeedback && (
                <Dialog open={openFeedback} sx={feedbackContainer}>
                    <DialogTitle color={Primary.main}>
                        <Typography variant="h6" color={Primary.main}>
                            {title}
                        </Typography>
                    </DialogTitle>
                    <FPAOneIcon sx={fpaOneIcon} />
                    {isFeedbackSubmitted ? (
                        <FeedbackSubmitted submittedUrl={submittedUrl} onFeedbackClose={onClose} />
                    ) : (
                        <FeedbackForm userName={userName} onFeedbackClose={onClose} onFeedbackSubmit={onFeedbackSubmit} />
                    )}
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default React.memo(Feedback);
