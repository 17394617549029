// libs
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

// utils
import { getClient, graphQlQueries } from "../utils/appsync";
import { CreateSIMTicketResponse } from "../utils/appsync/schema/API";

export const useCreateSimTicket = (): UseMutationResult<
    CreateSIMTicketResponse,
    Error,
    { userName: string; title: string; description: string }
> => {
    return useMutation<CreateSIMTicketResponse, Error, { userName: string; title: string; description: string }>({
        mutationFn: async ({ userName, title, description }) => {
            const { data }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlQueries.createSIMTicket,
                variables: {
                    input: { title, description, service: "A3S", requestor: userName },
                },
            });
            return data.createSIMTicket;
        },
    });
};
