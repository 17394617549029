export const Scenerios = {
    ACTUALS: "Actuals",
    "R&O - Current": "R&O - Current",
    GUIDANCE_Q2: "Guidance Q2",
    GUIDANCE_Q3: "Guidance Q3",
    GUIDANCE_Q4: "Guidance Q4",
    OP1_2024: "OP1 - 2024",
    OP1_2025: "OP1 - 2025",
    OP2_2024: "OP2 - 2024",
};

export const SimulationScenarios = (hideSimulationScenerios: Array<string>): Array<any> => {
    return Object.values(Scenerios)
        .filter((scenerio) => !hideSimulationScenerios.includes(scenerio))
        .map((itm: string) => ({
            value: itm,
            label: itm,
        }));
};

export const SimulationTimeRanges = [21, 22, 23, 24, 25, 26, 27, 28].map((yr: number) => ({
    value: `20${yr}`,
    label: `FY${yr}`,
}));
