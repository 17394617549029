const Secondary = {
    /**The token represents the secondary.main token */
    main: "#FF9900",
    /**The token represent the white color*/
    white: "#ffffff",
    /**The token represents 95% lighter shade of the secondary.white token */
    95: "#FFF5E6",
    /**The token represents 90% lighter shade of the secondary.white token */
    90: "#FFEBCC",
    /**The token represents 85% lighter shade of the secondary.white token */
    85: "#FFE0B3",
    /**The token represents 80% lighter shade of the secondary.white token */
    80: "#FFD699",
    /**The token represents 75% lighter shade of the secondary.white token */
    75: "#FFCC80",
    /**The token represents 70% lighter shade of the secondary.white token */
    70: "#FFC266",
    /**The token represents 65% lighter shade of the secondary.white token */
    65: "#FFB84D",
    /**The token represents 60% lighter shade of the secondary.white token */
    60: "#FFAD33",
    /**The token represents 55% lighter shade of the secondary.white token */
    55: "#FFA31A",
    /**The token represents 50% lighter shade of the secondary.white token */
    50: "#FF9900",
    /**The token represents 45% lighter shade of the secondary.white token */
    45: "#E68A00",
    /**The token represents 40% lighter shade of the secondary.white token */
    40: "#CC7A00",
    /**The token represents 35% lighter shade of the secondary.white token */
    35: "#B36B00",
    /**The token represents 30% lighter shade of the secondary.white token */
    30: "#995C00",
    /**The token represents 25% lighter shade of the secondary.white token */
    25: "#804D00",
    /**The token represents 20% lighter shade of the secondary.white token */
    20: "#663D00",
    /**The token represents 15% lighter shade of the secondary.white token */
    15: "#4D2E00",
    /**The token represents 10% lighter shade of the secondary.white token */
    10: "#331F00",
    /**The token represents 5% lighter shade of the secondary.white token */
    5: "#1A0F00",
    /**The token represents 0% lighter shade of the secondary.white token */
    0: "#000000",
};

export default Secondary;
