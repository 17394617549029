// libs
import React, { useEffect, useState } from "react";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// components
import SimActions from "./components/SimActions/SimActions";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, Container, Grid, Chip, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridFilterModel, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid-pro";

// utils
import { convertDateTime } from "../../../components/renderers/DateRenderer";
import { Sim, SimStatus } from "../../../utils/appsync/schema/API";

// services
import { useGetSimulationList, useCancelSimulation, useDeleteSimulation, useSubmitSimulation } from "../../../services/SimulationService";

// types
import { ChipColor } from "../../../types/mui";
import { SimActionType } from "./components/SimActions/SimActions.props";

// stores
import { setPageTitle } from "../../../store/page-title-reducer";
import { startLoading, stopLoading } from "../../../store/loader-reducer";

// hooks
import { useAlertMessageHandler } from "../../../hooks/useErrorMessageHandler";

// styles
import { Primary } from "../../../styles/colors";
import { simulationHistoryGrid } from "./SimulationHistory.style";

const SimulationHistory: React.FC = () => {
    const [simulationList, setSimulationList] = useState<Array<Sim>>([]);

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({} as GridFilterModel);

    const { selectedSandbox, allocationType, username } = useSelector((state: any) => state.userInfo);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { showErrorAlertMessage } = useAlertMessageHandler();

    const { data: simulationItems } = useGetSimulationList(selectedSandbox.sandbox_id, 100, filterModel);

    const cancelSimulation = useCancelSimulation();

    const deleteSimulation = useDeleteSimulation();

    const submitSimulation = useSubmitSimulation();

    useEffect(() => {
        dispatch(
            setPageTitle({
                title: "Simulations History",
                crumbs: [
                    {
                        title: "Simulations",
                    },
                ],
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (simulationItems) {
            setSimulationList(simulationItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulationItems]);

    const onCancelSimulation = async (sim: Sim): Promise<void> => {
        dispatch(startLoading());
        try {
            const canceledSim: Sim = await cancelSimulation.mutateAsync({
                sandbox_id: sim.sandbox_id,
                sim_id: sim.resource_id,
                userAlias: username,
            });

            const updatedSimulationList: Array<Sim> = simulationList.map((simulation: Sim) =>
                simulation.resource_id === canceledSim.resource_id ? canceledSim : simulation,
            );
            setSimulationList(updatedSimulationList);
        } catch ({ errors }) {
            showErrorAlertMessage(errors);
        }
        dispatch(stopLoading());
    };

    const onDeleteSimulation = async (sim: Sim): Promise<void> => {
        dispatch(startLoading());
        try {
            const deletedSim: Sim = await deleteSimulation.mutateAsync({
                sandbox_id: sim.sandbox_id,
                sim_id: sim.resource_id,
                is_shared: false,
                userAlias: username,
            });

            const updatedSimulationList: Array<Sim> = simulationList.filter(
                (simulation: Sim) => simulation.resource_id !== deletedSim.resource_id,
            );

            setSimulationList(updatedSimulationList);
        } catch ({ errors }) {
            showErrorAlertMessage(errors);
        }
        dispatch(stopLoading());
    };

    const onStartSimulation = async (sim: Sim): Promise<void> => {
        dispatch(startLoading());
        try {
            const startedSim: Sim = await submitSimulation.mutateAsync({
                sandbox_id: sim.sandbox_id,
                sim_id: sim.resource_id,
                userAlias: username,
            });

            const updatedSimulationList: Array<Sim> = simulationList.map((simulation: Sim) =>
                // Todo currently bypassing the failure state,
                // AI : Proper error message and null handling needed for this.
                startedSim && simulation.resource_id === startedSim.resource_id ? startedSim : simulation,
            );

            setSimulationList(updatedSimulationList);
        } catch ({ errors }) {
            showErrorAlertMessage(errors);
        }
        dispatch(stopLoading());
    };

    const onSimulationClick = (sim: Sim): void => {
        if (sim.status === "COMPLETED") {
            navigate({
                pathname: "/simulation/result/" + btoa(sim.resource_id),
                search: createSearchParams({
                    sandboxId: selectedSandbox.sandbox_id,
                    allocationType: allocationType.key,
                }).toString(),
            });
        } else {
            navigate({
                pathname: "/simulation/" + btoa(sim.resource_id),
                search: createSearchParams({
                    sandboxId: selectedSandbox.sandbox_id,
                    allocationType: allocationType.key,
                }).toString(),
            });
        }
    };

    const onSimAction = (simActionType: SimActionType, sim: Sim): void => {
        switch (simActionType) {
            case SimActionType.VIEW_REPORT:
            case SimActionType.VIEW_CONFIGURATION:
                onSimulationClick(sim);
                break;
            case SimActionType.START:
                onStartSimulation(sim);
                break;
            case SimActionType.CANCEL:
                onCancelSimulation(sim);
                break;
            case SimActionType.DELETE:
                onDeleteSimulation(sim);
                break;
        }
    };

    const COLUMN_DEFINITION: Array<GridColDef> = [
        {
            field: "name",
            headerName: "Name",
            flex: 4,
            renderCell: (params: GridRenderCellParams<any, string>) => (
                <Button variant="text" onClick={() => onSimulationClick(params.row)}>
                    <Typography color={Primary[0]} variant="body2">
                        {params.value}
                    </Typography>
                    <ChevronRightIcon sx={{ color: Primary[0] }} />
                </Button>
            ),
        },
        {
            field: "allocation_type",
            headerName: "Allocation Type",
            flex: 2,
        },
        {
            field: "status",
            headerName: "Status",
            filterable: false,
            flex: 2,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                let variant = "filled";
                let color: ChipColor = "info";
                switch (params.value?.toUpperCase()) {
                    case SimStatus.STARTED:
                        color = "primary";
                        break;
                    case SimStatus.FAILED:
                        color = "error";
                        break;
                    case SimStatus.CREATED:
                        color = "default";
                        variant = "outlined";
                        break;
                    case SimStatus.COMPLETED:
                        color = "success";
                        break;
                    case SimStatus.CANCELED:
                        color = "warning";
                        break;
                    default:
                        color = "default";
                }
                return <Chip label={params.value.toLowerCase()} color={color} size="small" variant={variant as any} />;
            },
        },
        {
            field: "userAlias",
            headerName: "Created By",
            flex: 2,
            sortable: false,
        },
        {
            field: "started_at",
            headerName: "Started At",
            flex: 3,
            filterable: false,
            sortable: false,
            valueGetter(params) {
                return convertDateTime(params.value);
            },
        },
        {
            field: "finished_at",
            headerName: "Finished At",
            flex: 3,
            filterable: false,
            sortable: false,
            valueGetter(params) {
                return convertDateTime(params.value);
            },
        },
        {
            field: "actions",
            headerName: "",
            flex: 1,
            filterable: false,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, string>) => <SimActions sim={params.row} onSimAction={onSimAction} />,
        },
    ];

    const getRowId = (row: Sim): string => row.sandbox_id + "::" + row.resource_key;

    const onFilterChange = React.useCallback((model: GridFilterModel) => {
        setFilterModel({ ...model });
    }, []);

    if (!simulationList.length) {
        return (
            <Container>
                No Simulations Yet. Try Creating a <Link to="/simulation">New Simulation</Link>
            </Container>
        );
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataGridPro
                    sx={simulationHistoryGrid}
                    getRowId={getRowId}
                    rows={simulationList}
                    columns={COLUMN_DEFINITION}
                    slots={{ toolbar: GridToolbar }}
                    density="standard"
                    filterMode="server"
                    isRowSelectable={() => false}
                    onFilterModelChange={onFilterChange}
                />
            </Grid>
        </Grid>
    );
};

export default React.memo(SimulationHistory);
