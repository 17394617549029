// libs
import React from "react";

// components
import { SvgIcon, SxProps } from "@mui/material";

// props
interface IFPAOneIconProps {
    sx?: SxProps;
}

const FPAOneIcon: React.FC<IFPAOneIconProps> = ({ sx }) => {
    return (
        <SvgIcon fontSize="large" sx={sx}>
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="8" viewBox="0 0 41 8" fill="none">
                <path
                    d="M36.2855 2.94504C24.0309 8.2731 7.64714 3.38904 0.987061 0.281006C18.9693 13.6012 32.9554 5.60907 35.6195 4.27705C38.2835 2.94504 37.6175 2.27903 36.2855 2.94504Z"
                    fill="#0070F0"
                />
                <path
                    d="M38.8445 2.94494C39.3773 0.813721 35.5145 1.16892 33.5164 1.61293C32.8504 0.946831 37.5125 0.281006 39.5105 0.281006C41.5085 0.281006 40.8425 3.61104 38.8445 6.27507C36.8465 8.9391 38.1785 5.60897 38.8445 2.94494Z"
                    fill="#0070F0"
                />
            </svg>
        </SvgIcon>
    );
};

export default React.memo(FPAOneIcon);
