import Handsontable from "handsontable";
import React from "react";
import ReactDOM from "react-dom/client";
import BusinessInclusionsRenderer from "../../components/renderers/BusinessInclusionsRenderer";

const businessInclusionsName = "business-inclusions";

export const businessInclusionsRenderer = {
    name: businessInclusionsName,
    register() {
        const renderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
            if (value) {
                // Optionally include `BaseRenderer` which is responsible for
                // adding/removing CSS classes to/from the table cells.
                //Handsontable.renderers.BaseRenderer.apply(this, arguments);

                const element = document.createElement("div");
                td.innerHTML = "";
                td.appendChild(element);
                const root = ReactDOM.createRoot(element);
                root.render(
                    <React.StrictMode>
                        <BusinessInclusionsRenderer indx={row} businessGroups={value} />
                    </React.StrictMode>,
                );
            }
        };

        Handsontable.renderers.registerRenderer(businessInclusionsName, renderer);
        return renderer;
    },
};
