// libs
import React from "react";

// components
import { TextField } from "@mui/material";

// types
import { SxProps } from "@mui/material";

// styles
import { pageTitleBarTextField } from "./PageTitleBarTextField.style";

interface IPageTitleBarTextFieldProps {
    label: string;
    value: string;
    sx?: SxProps;
    readonly?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PageTitleBarTextField: React.FC<IPageTitleBarTextFieldProps> = (props: IPageTitleBarTextFieldProps) => {
    const onChangeHandler = (e: any): void => {
        props.onChange?.(e.currentTarget.value);
    };

    return (
        <TextField
            disabled={!!props.readonly}
            variant="outlined"
            placeholder={props.label}
            value={props.value ?? ""}
            onChange={onChangeHandler}
            autoComplete="off"
            size="small"
            sx={{ ...pageTitleBarTextField, ...props?.sx }}
        />
    );
};

export default React.memo(PageTitleBarTextField);
