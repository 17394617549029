const Success = {
    /**The token represents the success.main token */
    main: "#1F7A1F",
    /**The token represent the white color*/
    white: "#ffffff",
    /**The token represents 95% lighter shade of the success.white token */
    95: "#EBFAEB",
    /**The token represents 90% lighter shade of the success.white token */
    90: "#D6F5D6",
    /**The token represents 85% lighter shade of the success.white token */
    85: "#C2F0C2",
    /**The token represents 80% lighter shade of the success.white token */
    80: "#ADEBAD",
    /**The token represents 75% lighter shade of the success.white token */
    75: "#99E699",
    /**The token represents 70% lighter shade of the success.white token */
    70: "#85E085",
    /**The token represents 65% lighter shade of the success.white token */
    65: "#70DB70",
    /**The token represents 60% lighter shade of the success.white token */
    60: "#5CD65C",
    /**The token represents 55% lighter shade of the success.white token */
    55: "#47D147",
    /**The token represents 50% lighter shade of the success.white token */
    50: "#33CC33",
    /**The token represents 45% lighter shade of the success.white token */
    45: "#2EB82E",
    /**The token represents 40% lighter shade of the success.white token */
    40: "#29A329",
    /**The token represents 35% lighter shade of the success.white token */
    35: "#248F24",
    /**The token represents 30% lighter shade of the success.white token */
    30: "#1F7A1F",
    /**The token represents 25% lighter shade of the success.white token */
    25: "#196619",
    /**The token represents 20% lighter shade of the success.white token */
    20: "#145214",
    /**The token represents 15% lighter shade of the success.white token */
    15: "#0F3D0F",
    /**The token represents 10% lighter shade of the success.white token */
    10: "#0A290A",
    /**The token represents 5% lighter shade of the success.white token */
    5: "#051405",
    /**The token represents 0% lighter shade of the success.white token */
    0: "#000000",
};

export default Success;
