// libs
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";

// components
import { AllCostPool } from "../components/AllCostPool";
import { SimulationInput } from "./components/SimulationInput";
import { Grid, Box, Typography, Stack, CircularProgress } from "@mui/material";
import { PageTitleBarSelect } from "../../../components/common/PageTitleBar/PageTitleBarSelect";
import { PageTitleBarTextField } from "../../../components/common/PageTitleBar/PageTitleBarTextField";

// icons
import { InsertDriveFileOutlined } from "@mui/icons-material";

// stores
import { setPageTitle } from "../../../store/page-title-reducer";

// types
import { IInputType } from "../../../types/utilities";

// services
import { useGetSimulation, useGetEmbedUrlForSimulationReport } from "../../../services/SimulationService";

// constants
import { INPUT_TYPES } from "../../../utils/constants";

// constants
import { Scenerios, SimulationScenarios, SimulationTimeRanges } from "../constants/Scenerios";

// styles
import { Action } from "../../../styles/colors";
import { titleContainer, noInputSelected, quickSightDashboard } from "./SimulationResult.style";

const SimulationResult: React.FC = () => {
    const [simulationId, setSimulationId] = useState(null);

    const [simulationData, setSimulationData] = useState<any>({});

    const [disabledTimeRangeSelector, toggleTimeRangeSelector] = useState<boolean>(false);

    const { resourceKey } = useParams();

    const { email, selectedSandbox, allocationType } = useSelector((state: any) => ({
        email: state.userInfo.email,
        selectedSandbox: state.userInfo.selectedSandbox,
        allocationType: state.userInfo.allocationType,
    }));
    const dispatch = useDispatch();

    const { data: embedUrlForSimulationReport } = useGetEmbedUrlForSimulationReport(email, simulationId);

    const { data: simData } = useGetSimulation(selectedSandbox.sandbox_id, simulationId);

    useEffect(() => {
        if (simData) {
            setSimulationData(simData);
        }
    }, [simData]);

    useEffect(() => {
        dispatch(
            setPageTitle({
                title: "Report",
                icon: "",
                crumbs: [
                    {
                        title: "Simulations",
                        path: "/simulations",
                    },
                    {
                        title: "Report",
                        icon: "",
                    },
                ],
            }),
        );

        // TODO: temp disable the timeRange in case of
        // Scenerio other then `Actuals` & `R&O - Current`, will remove this once we will have
        // range selector (year and month both) feature.
        const secenerio = JSON.parse(simulationData.configs || "{}").scenario || "";
        toggleTimeRangeSelector(!(secenerio === Scenerios.ACTUALS || secenerio === Scenerios["R&O - Current"]));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulationData]);

    useEffect(() => {
        if (selectedSandbox?.sandbox_id && resourceKey) {
            setSimulationId(atob(resourceKey));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSandbox, resourceKey]);

    const getSimulationScenario = useMemo(() => {
        return JSON.parse(simulationData.configs || "{}").scenario || "";
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulationData]);

    const getSelectedTimeRanges = useMemo(() => {
        if (!disabledTimeRangeSelector) {
            let timeRange = JSON.parse(simulationData.configs || "{}");
            if (!!timeRange.startPeriod && !!timeRange.endPeriod) {
                timeRange = [timeRange.startPeriod, timeRange.endPeriod];
            }
            const timeRanges: Array<string> = [];
            if (timeRange?.length) {
                const startPeriod = parseInt(timeRange[0].substring(0, timeRange[0].length - 2));
                const endPeriod = parseInt(timeRange[1].substring(0, timeRange[1].length - 2));
                for (let i = startPeriod; i <= endPeriod; i++) {
                    timeRanges.push(i.toString());
                }
            }
            return timeRanges;
        }
    }, [disabledTimeRangeSelector, simulationData]);

    const getIsAllCostPoolSelected = useMemo(() => {
        return JSON.parse(simulationData.configs || "{}").simulate_all_costpools || false;
    }, [simulationData]);

    const getSelectedInput = useCallback(
        (fieldName: string) => {
            const configs = JSON.parse(simulationData.configs || "{}");
            return configs[fieldName] ?? "";
        },
        [simulationData],
    );

    const renderAllCostPool = (): React.ReactElement => {
        // add a temp check to hide all cost pool feature for all sandbox
        // except RFA.
        if (!selectedSandbox?.sandbox_name.toLowerCase().includes("rfa")) {
            return <></>;
        }

        return <AllCostPool readOnly={true} isAllCostPoolSelected={getIsAllCostPoolSelected} />;
    };

    const renderSimulationInputs = (): React.ReactElement | Array<React.ReactElement> => {
        const selectedSimulationInputs = INPUT_TYPES.filter((inputTypeItem: IInputType) => {
            return !!getSelectedInput(inputTypeItem.key).length;
        }).map((inputTypeItem: IInputType) => {
            return (
                <SimulationInput
                    allocationType={allocationType}
                    inputType={inputTypeItem}
                    selectedInput={getSelectedInput(inputTypeItem.key)}
                />
            );
        });

        if (!selectedSimulationInputs.length) {
            return (
                <Box sx={noInputSelected}>
                    <Typography variant="body2">No Input Selected</Typography>
                </Box>
            );
        }

        return selectedSimulationInputs;
    };

    const renderQuickSightReport = (): React.ReactElement => {
        if (!embedUrlForSimulationReport?.length) {
            return (
                <Grid item sx={{ backgroundColor: Action.white, p: 4, borderRadius: 2 }}>
                    <Stack alignItems="center" spacing={2}>
                        <CircularProgress />
                        <Typography>Loading...</Typography>
                    </Stack>
                </Grid>
            );
        }

        return (
            <iframe
                title="Quicksight Dashboard for the simulation result"
                src={embedUrlForSimulationReport}
                style={{ height: "100%", width: "100%" }}
            />
        );
    };

    if (!simulationData.resource_key) {
        return <></>;
    }

    return (
        <Grid>
            <Box sx={titleContainer}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <InsertDriveFileOutlined />
                    <PageTitleBarTextField readonly={true} label="Simulation Result" value={simulationData.name} />
                    <PageTitleBarSelect
                        readonly={true}
                        label="Scenario"
                        value={getSimulationScenario}
                        options={SimulationScenarios([])} // keeping all scenerio on reporting page.
                    />
                    {!disabledTimeRangeSelector && (
                        <PageTitleBarSelect
                            readonly={true}
                            label="Time"
                            value={getSelectedTimeRanges}
                            options={SimulationTimeRanges}
                            multiple={true}
                        />
                    )}
                </Stack>
            </Box>
            {renderAllCostPool()}
            <Stack spacing={1} sx={{ pt: 2 }}>
                <Typography variant="body1" color={Action[70]} p={1}>
                    Simulation Inputs
                </Typography>
                {renderSimulationInputs()}
            </Stack>
            <Stack spacing={1} sx={{ pt: 2 }}>
                <Typography variant="body1" color={Action[70]}>
                    Quicksight Report
                </Typography>
                <Grid container alignContent="center" justifyContent="center" sx={quickSightDashboard}>
                    {renderQuickSightReport()}
                </Grid>
            </Stack>
        </Grid>
    );
};

export default React.memo(SimulationResult);
