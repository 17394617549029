const Error = {
    /**The token represents the error.main token */
    main: "#E60000",
    /**The token represent the white color*/
    white: "#ffffff",
    /**The token represents 95% lighter shade of the error.white token */
    95: "#FFE6E6",
    /**The token represents 90% lighter shade of the error.white token */
    90: "#FFCCCC",
    /**The token represents 85% lighter shade of the error.white token */
    85: "#FFB3B3",
    /**The token represents 80% lighter shade of the error.white token */
    80: "#FF9999",
    /**The token represents 75% lighter shade of the error.white token */
    75: "#FF8080",
    /**The token represents 70% lighter shade of the error.white token */
    70: "#FF6666",
    /**The token represents 65% lighter shade of the error.white token */
    65: "#FF4D4D",
    /**The token represents 60% lighter shade of the error.white token */
    60: "#FF3333",
    /**The token represents 55% lighter shade of the error.white token */
    55: "#FF1A1A",
    /**The token represents 50% lighter shade of the error.white token */
    50: "#FF0000",
    /**The token represents 45% lighter shade of the error.white token */
    45: "#E60000",
    /**The token represents 40% lighter shade of the error.white token */
    40: "#CC0000",
    /**The token represents 35% lighter shade of the error.white token */
    35: "#B30000",
    /**The token represents 30% lighter shade of the error.white token */
    30: "#990000",
    /**The token represents 25% lighter shade of the error.white token */
    25: "#800000",
    /**The token represents 20% lighter shade of the error.white token */
    20: "#660000",
    /**The token represents 15% lighter shade of the error.white token */
    15: "#4D0000",
    /**The token represents 10% lighter shade of the error.white token */
    10: "#330000",
    /**The token represents 5% lighter shade of the error.white token */
    5: "#1A0000",
    /**The token represents 0% lighter shade of the error.white token */
    0: "#000000",
};

export default Error;
