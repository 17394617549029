// libs
import React from "react";
import { useNavigate } from "react-router";

// components
import { Card, CardContent, Grid, List, ListItemButton, ListItemText, Typography } from "@mui/material";

// icons
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// styles
import { theme } from "../../../../styles";
import { sandboxInfo, moreResources, sandboxInfoDetail, moreResourcesLink } from "./MoreResources.style";

// constants
import { MCM_INPUT_TOOL_LINK } from "../../../../constants";

const QUICK_LINKS = [
    {
        label: "A3S Wiki",
        link: "https://w.amazon.com/bin/view/GFT/FP%26A_Tools/A3S",
        isExternal: true,
    },
    {
        label: "Restatement Wiki",
        link: "https://w.amazon.com/bin/view/Corporate-FPA/RFA/MDGA/Restatement",
        isExternal: true,
    },
    {
        label: "Allocation MCM Tool",
        link: MCM_INPUT_TOOL_LINK,
        isExternal: true,
    },
];

const MoreResources: React.FC = () => {
    const navigate = useNavigate();

    const navigateTo = (link: string): void => {
        navigate(link);
    };

    return (
        <Grid
            container
            md={3}
            sx={{
                pointerEvents: "none",
            }}>
            <Grid
                item
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "16px",
                    width: "100%",
                    pointerEvents: "all",
                }}>
                <Card sx={sandboxInfo}>
                    <CardContent>
                        <Typography variant="subtitle2">What is a Sandbox?</Typography>
                        <Typography sx={sandboxInfoDetail}>
                            A Sandbox is a controlled, virtual environment used to simulate corporate allocation and analyze different
                            allocation strategies without affecting real-world Production allocations. Users with access to the same sandbox
                            can view and collaborate on each other's simulations, while users in different sandboxes are isolated from each
                            other's data.
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={moreResources}>
                    <CardContent>
                        <Typography variant="subtitle2">More Resources</Typography>
                        <List>
                            {QUICK_LINKS.map((quickLink: any, indx: number) => {
                                if (quickLink.isExternal) {
                                    return (
                                        <ListItemButton
                                            key={quickLink.label}
                                            dense
                                            target="_blank"
                                            disableGutters
                                            href={quickLink.link}
                                            rel="noopener noreferrer"
                                            sx={{
                                                display: "flex",
                                                columnGap: "4px",
                                                justifyContent: "space-between",
                                                color: theme.palette.primary.main,
                                            }}>
                                            <Typography variant="body2">{quickLink.label}</Typography>
                                            <OpenInNewIcon fontSize="small" />
                                        </ListItemButton>
                                    );
                                }
                                return (
                                    <ListItemButton
                                        key={quickLink.label}
                                        onClick={() => navigateTo(quickLink.link)}
                                        divider={QUICK_LINKS.length > indx + 1}
                                        dense
                                        disableGutters>
                                        <ListItemText primary={quickLink.label} sx={moreResourcesLink} />
                                    </ListItemButton>
                                );
                            })}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default React.memo(MoreResources);
